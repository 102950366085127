.app-loading {
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.stack-layout > form {
  max-width: 90%;
} 

@media only screen and (max-width : 1050px) {
  .stack-layout > form {
    max-width: 60%;
  } 
}

@media only screen and (max-width : 900px) {
  .stack-layout > form {
    max-width: 100%;
  }
}

.app-progress-bar {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 30;
}

.zrx-tabs-wrapper {
  height: 100%;
}

.zrx-tabs-wrapper > .Polaris-Card {
  height: 100%;
}

.zrx-tabs-wrapper > .Polaris-Card > div {
  height: 100%;
}

#faq-panel { height: 100% }


.app-landing-wrapper .app-loading-app-icon-img {
  width: 38px;
  height: 35px;
}

.app-landing-wrapper .app-loading-skip-onboarding { margin-left: auto;}

.app-landing-wrapper .app-loading-app-icon {
  display: flex;
  place-self: center;
}

.app-landing-wrapper {
  padding: 40px;
  /*height: 100%;*/
}

.app-landing-wrapper .app-landing-content-wrapper {
  height: 100%;
  padding: 100px 200px;
  display: flex;
  flex-direction: column;
}

@media (max-width: 720px) {
  .app-landing-wrapper .app-landing-content-wrapper {
    padding: 0;
  }
}

.app-landing-wrapper .app-landing-title {
  font-size: 30px;
  font-weight: 600;
}

.app-landing-wrapper .app-landing-subtitle {
  font-size: 20px;
  font-weight: 500;
  margin-top: 5px;
}

.app-landing-wrapper .app-landing-title-wrapper {
  margin-bottom: 40px;
}

.app-landing-wrapper .app-landing-logo-wraper {
  display: flex;
  align-items: center;
}

.app-landing-wrapper .app-loading-app-name {
  margin-left: 5px;
  font-weight: 600;
  font-size: 18px;
}

.app-landing-wrapper .app-landing-bullet {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.app-landing-wrapper .app-landing-bullets-icon > svg {
  width: 15px;
  height: 15px;
}

.app-landing-wrapper .app-landing-bullets-icon {
  width: 15px;
  height: 15px;
}

.app-landing-wrapper .app-landing-bullets-text {
  margin-left: 20px;
}

.app-landing-wrapper .app-landing-get-started-btn {
  margin-right: 8px;
}


.app-landing-wrapper .app-landing-get-started-btn .Polaris-Button:before {
  content: "\2192";
  position: absolute;
  right: 12px;
}


.app-landing-wrapper .app-landing-get-started-btn .Polaris-Button__Text {
  padding-right: 16px;
}

@media (max-width: 720px) {
  .app-landing-wrapper .app-landing-get-started-btn .Polaris-Button__Text {
    font-size: 13px;
  }

  .app-landing-wrapper .app-landing-skip-btn .Polaris-Button__Text {
    font-size: 13px;
  }
  .app-landing-wrapper .app-landing-cat-ball > img {
    width: -webkit-fill-available;
  }
}

.app-landing-wrapper .app-landing-buttons-wrapper {
  display: flex;
  align-items: center;
  margin-top: 60px;
  margin-bottom: 25px;
}
