.warranty-redeem-container {
    padding: 20px;
}

.warranty-redeem-container h2 {
    padding-left: 20px;
}

.warranty-redeem-list-item {
    border: 1px solid #C4CDD5;
    padding: 10px;
    display: flex;
    align-items: center;
}

.warranty-redeem-list {
    width: 100%;
    min-height: 100px;
}

.warranty-redeem-list > div {
    width: 100%;
}

.warranty-redeem-list-item-redeem-btn {
    margin-left: auto;
}

.warranty-redeem-list-item.header .warranty-redeem-list-item-redeem-btn {
    padding-right: 25px;

}

.warranty-redeem-list-item-price,.warranty-redeem-list-item-discount,.warranty-redeem-list-item-status {
    width: 20%;
    text-align: left;
}

.warranty-redeem-list-item.header {
    text-align: left;
}

.warranty-redeem-list-item.header > .warranty-redeem-list-item-price,.warranty-redeem-list-item-discount,.warranty-redeem-list-item-status,.warranty-redeem-list-item-name {
    text-align: left;
}

@media (max-width: 720px) {

    .warranty-redeem-list-item-price, .warranty-redeem-list-item-discount, .warranty-redeem-list-item-status, .warranty-redeem-list-item-name {
        display: block;
        text-align: left;
        width: 100%;
        padding: 5px;
    }



    .warranty-redeem-list-item {
        display: block;
    }

    .warranty-redeem-list-item.header {
        display: none;
    }

    .warranty-redeem-list .Polaris-Card__Section {
        padding: 0.7rem;
    }


    .warranty-redeem-list-item-redeem-btn {
        margin-top: 10px;
        display: block;
    }
}

@media (min-width: 721px) {
    .mobile-prefix {
        display: none;
    }

}

.warranty-redeem-list-item-name {
    min-width: 25%;
}

.spinner-wrapper.redeem {
    display: flex;
    justify-items: center;
}

