.schedule-wrapper {
  padding-top: 0px;
  .react-datetime-picker__calendar {
    z-index: 99999;
  }
  
  .react-datetime-picker__wrapper  {
    span, select, input {
      text-align: center;
      font-size: 14px;
      min-width: 16px;
    }
  }

  .react-datetime-picker__inputGroup__input {
    min-width: inherit;
  }
  
  .react-datetime-picker__clock {
    z-index: 999;
  }

  .schedule-offer-end-title {
    margin-right: 10px;
    margin-left: 25px;
  }

  .schedule-offer-start-title {
    margin-right: 7px;
  }
  .react-datetime-picker__button svg {
    width: 11px;
}
}


