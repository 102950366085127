.bundle-product-image.vd-item-image {
    margin-right:15px;
}

.delete-tier-button-wrapper {
    margin-top: 8px;
    text-align: right;
}

.mark-popular-remove-wrapper {
    margin-top: 15px;
}

.best-value-remove-wrapper {
    margin-top: 15px;
}

.vd-item {
    display: flex;
    align-items: center;
    border: 1px solid #d6d6d6;
    padding: 10px;
    margin-top: -1px;
}

.vd-items-wrapper {
    margin-top: 10px;
    min-height: 250px;

}

.vd-edit-wrapper {
    margin-left: auto;
    display: flex;
    align-items: center;
    padding-left: 10px;
}

.vd-switch-preview-wrapper >  button {
    min-width: 100px;
}

.vd-item-remove {
    margin-left:10px;
    cursor: pointer;
}

.vd-short-code-wrapper {
    min-width: 86px;
    text-align: center;
}

.vd-switch-preview-wrapper {
    min-width: 106px;
    display: flex;
    justify-content: center;
}

.vd-offer-name {
    margin-right:10px;
    font-weight: 600;
}

.vd-item-view {
    margin-left: 0px;
    margin-right: 5px;
    cursor: pointer;
}

.duplicate-vd-item {
    margin-left: 8px;
    margin-right: 7px;
}

.vd-display-group-wrapper {
    padding: 15px;
    border: 1px solid #d6d6d6;
    margin-top: 5px;

}

.vd-display-item {
    display: flex;
    padding: 15px;
    border: 1px solid #dedede;
    margin-top: -1px;
}

.vd-remove-display-item {
    margin-left: auto;
}

.vd-display-item-image {
    width: 25px;
    height: 25px;
    background-size: contain;
    margin-right: 5px;
}

.vd-product-to-show-on-title {
    display: flex;
    align-items: center;
}

.vd-display-items-wrapper {
    margin-top: 5px;
}

.vd-enable-disable-wrapper {
    margin-right: 5px;
        min-width: 90px;
    display: flex;
    justify-content: center;
}

.vd-type-amount-wrapper {
    display: flex;
}

.vd-type-amount-wrapper > div:first-child {
    width: 100%;
}

.vd-type-amount-wrapper > div:nth-child(2) {
    min-width: 150px;
    margin-left: 10px;
}

.vd-tier-wrapper {
    padding: 10px;
    border: 1px solid #d0d0d0;
}

.vd-tier-wrapper > .vd-tier-qty-wrapper {
    position: relative;
}

@media (min-width: 720px) {
    .vd-tier-wrapper > .vd-tier-qty-wrapper {
        max-width: 120px;
        width: 120px;
    }
}

.vd-tier-wrapper > .vd-tier-qty-wrapper > .vd-tier-alt-qty {
    position: absolute;
    right: 0;
    z-index: 21;
    top: 24px;
    padding-top: 5px;
    padding-right: 6px;
}

.vd-tier-wrapper > .vd-tier-qty-wrapper > .vd-tier-alt-qty button {
    height: 26px;
    min-height: inherit;
    min-width: inherit;
    width: 26px;
    margin-right: 1px;
}


.delete-tier-button-wrapper .Polaris-Button--plain svg {
    fill: black;
}

.delete-tier-button-wrapper button { outline: none; }

.pre-defined-variants-box {
    padding: 0px 20px 20px 20px;
    border: 1px solid #dddddd;
    margin: 10px 30px 20px 30px;
    display: flex;
    align-items: flex-end;
}

.pre-defined-variants-box > div:first-child {
    width: 85%;
    margin-right: 5px;

}


.pre-defined-variants-box > div:nth-child(2) {
    width: 15%;
    margin-right: 5px;

}

.pre-defined-variants-box > button {
    margin-left: auto;
    max-width: 42px;
    max-height: 45px;
    border: none;
}

.vd-short-code-copy {
    margin-top: 10px;
    cursor: pointer;
}

.vd-titles-locale-wrapper {
    padding: 2px 10px 15px 10px;
    border: 1px solid lightgrey;
    border-radius: 5px;
    margin-top: 10px;
}

.pre-defined-variants-wrapper {
    margin-top: 10px;
}

.vd-dashboard-buttons-wrapper > .vd-upgrade-offers-btn {
        margin-left: auto;
}

.vd-dashboard-buttons-wrapper > .vd-create-offer-btn {
    margin-right: 5px;
}

.vd-too-many-auto-discounts-error {
    color: red;
    margin-bottom: 10px;
}

.vd-dashboard-buttons-wrapper {
    display: flex;
}

.vd-legacy-offers-title {
    margin-bottom: 5px;
    font-weight: 500;;
    margin-top: 15px;
}


.vd-pd-func-offers-title {
    margin-bottom: 5px;
    margin-top: 15px;
    font-weight: 500;;
}

.vd-downgrade-offers-btn {
    display: flex;
    margin-left: auto;
    padding-left: 5px;
}


.vd-upgrade-offers-wrapper {
    display: flex;
    margin-left: auto;
}
