.bogo-list-wrapper {
    margin-bottom: 10px;
    .bogo-create-offer-wrapper {
        margin-bottom: 10px;
        .bogo-manage-pages-btn {
            margin-left: 5px;
        }
    }
    .bogo-list-item {
        display: flex;
        align-items: center;
        padding: 9px 15px 9px 10px;
        border: 1px solid #d5d5d5;
        margin-top: -1px;
        .bogo-list-item-name {
            margin-left: 5px;
        }
        .bogo-list-action-container {
            margin-left: auto;
            display: flex;
            align-items: center;
            .bogo-list-item-preview {
                margin-left: 9px;
                cursor: pointer;
            }
            .bogo-list-item-duplicate-wrapper {
                margin-left: 9px;
                cursor: pointer;
            }

            .bogo-list-item-delete {
                margin-left: 9px;
                cursor: pointer;
            }

            .bogo-list-item-edit {
                margin-left: 3px;
                cursor: pointer;
            }

            .bogo-list-item-enable-disable {
                margin-left: 3px;
                cursor: pointer;
            }

            .bogo-list-item-enable-disable > button {
                width: 87px;
            }

            .bogo-list-item-shortcode {
                width: 87px;
                display: inline-block;
                text-align: center;
            }

            .bogo-list-item-publish > button {
                width: 100px;
            }
        }
    }
}

.bogo-edit-wrapper {

    .bogo-save-button-wrapper {
        margin-left: 6px;
    }

    >.bogo-trigger-items-wrapper {
        /*background-color: #ebebeb;*/
        /*border: 1px solid gray;*/
        margin-top: 5px;
        width: 100%;
        border: 1px solid #e5e5e5;
        padding: 11px;
        border-radius: 8px;

        .bogo-trigger-items-edit-wrapper {
            display: flex;
            align-items: center;
            padding: 13px;
            border: 1px solid rgb(217, 217, 217);
            background-color: #f5f5f5;
            border-radius: 8px;


            > button {
                height: 33px;
                min-height: 33px;
                margin-right: 5px;
            }
        }

        .bogo-min-qty-wrapper {
            margin-top: 10px;
            max-width: 130px;

            .bogo-min-qty-title {
                margin-right: 10px;
                margin-bottom: 3px;
                display: block;
            }

            /*input {
                max-width: 45px;
            }

            .Polaris-TextField--hasValue {
                max-width: 73px;
            }*/
        }

        .bogo-trigger-items-list {
            /* border: 1px solid #bababa; */
            overflow: scroll;
            align-items: center;
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            grid-auto-rows: 39px;

            .bogo-trigger-item {
                display: flex;
                width: auto;
                max-width: 200px;
                border: 1px solid #ccc5c5;
                padding: 5px;
                border-radius: 7px;
                margin-right: 3px;
                align-items: center;

                .bogo-trigger-item-title {
                    display: block;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                .bogo-trigger-item-qty {
                    border: 1px solid gray;
                    border-radius: 50%;
                    padding: 4px;
                    width: 20px;
                    height: 19px;
                    font-size: 11px;
                    display: flex;
                    place-items: center;
                }

                .bogo-trigger-item-image {
                    margin-right: 5px;
                }
            }
        }
    }

    >.bogo-offer-items-wrapper {
        width: 100%;
        border: 1px solid #e5e5e5;
        padding: 11px;
        border-radius: 8px;

        .bogo-offer-items-edit-wrapper {

            display: flex;
            align-items: center;
            display: flex;
            align-items: center;
            padding: 13px;
            border: 1px solid rgb(217, 217, 217);
            background-color: #f5f5f5;
            border-radius: 8px;

            > button {
                height: 33px;
                min-height: 33px;
                margin-right: 5px;
            }
            .bogo-offer-items-list {
                display: flex;
                .bogo-offer-item {
                    display: flex;
                    width: auto;
                    max-width: 200px;
                    border: 1px solid #ccc5c5;
                    padding: 5px;
                    border-radius: 7px;
                    margin-right: 3px;
                    align-items: center;

                    .bogo-offer-item-title {
                        display: block;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    .bogo-offer-item-qty {
                        border: 1px solid gray;
                        border-radius: 50%;
                        padding: 4px;
                        width: 20px;
                        height: 19px;
                        font-size: 11px;
                        display: flex;
                        place-items: center;
                    }
                }
            }
        }
    }

    .bogo-invalid-mutual-items-error-msg {
        color: red;
    }

    .bogo-trigger-items-title,.bogo-offer-items-title {
        font-weight: 600;
        margin-top: 17px;
        display: block;

        &.invalid {
            border: red 1px solid;
        }
    }

    .bogo-location-wrapper {
        padding: 10px;
        background-color: #efefef;
        margin-top: 10px;
        border: 1px solid #c2c2c2;
        border-radius: 10px;

        .bogo-product-location-wrapper, .bogo-cart-location-wrapper {
            display: flex;
            flex-direction: column;
            background-color: white;
            padding: 10px;
            border: 1px solid #c2c2c2;
        }

        .bogo-cart-location-title {
            margin-top: 7px;
        }
    }
}

.bogo-shortcode-modal {
    padding: 30px 30px;
    display: flex;
    flex-direction: column;
}

.bogo-shortcode-copy {
    margin-top: 10px;
    cursor: pointer;
}
