.warranty-product-label {
    margin-bottom: 5px;
}

.upload-warranty-image {
    width: 175px;
}

.upload-warranty-image-div {
    text-align: center;
}

.search-products.warranty {
    width: 100%;
}

.item-image.warranty{
    width: 45px;
}

.warranty-searchable-item {
    margin-left: 10px;
}


.product-container.searchable.warranty {
    display: flex;
    align-items: center;
}

.warranty-product-image {
    background-size: contain;
    background-repeat: no-repeat;
    width: 45px;
    height: 45px;
}

.warranty-line-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    border: 1px solid #C4CDD5;
    padding: 10px;
}

.warranty-item-title {
    margin-left: 10px;
}

.restore-image-button {
    margin-bottom: 10px;
    margin-top: 10px;
}

.warranty-list-item {
    display: flex;
    align-items: center;
    border: 1px solid #C4CDD5;
    padding: 10px;
    border-bottom: 1px solid transparent;
}

.warranty-list-item.header {
    text-align: left;
    padding-left: 10px;
    font-weight: 500;
}



.warranty-list-item:last-child {
    border-bottom: 1px solid #C4CDD5;
}

.warranty-list-buttons {
    margin-left: auto;
    display: flex;
    align-self: center;
    align-items: center;
}

.warranty-edit-button {
    margin-right: 10px;
}

.warranty-name,.warranty-price,.warranty-percentage,.warranty-product-title, .warranty-bulk-item-percentage,.warranty-bulk-item-price
{
    margin-right: 15px;
}

.warranty-name {
    width: 200px;
}

.warranty-product-image {
    min-width: 225px;
}

.warranty-product-title {
    width: 200px;
}

.warranty-percentage, .warranty-price, .warranty-bulk-item-percentage, .warranty-bulk-item-price {
    width: 75px;
    display: flex;
    justify-content: center;
}

.warranty-price,.warranty-percentage,.warranty-bulk-item-percentage,.warranty-bulk-item-price {
    min-width: 35px;
}

@media (max-width: 720px) {

    .warranty-name,.warranty-price,.warranty-percentage,.warranty-product-title,.warranty-product-image,.warranty-bulk-item-percentage,.warranty-bulk-item-price {
        margin-right: 0px;
        padding-left: 5px;
        font-size: 12px;
        width: 25%;
        min-width: 0;

    }

    .warranty-product-image {
        width: 30px;
        height: 30px;
        margin: 0 20px 0 5px;
    }
    .warranty-list-buttons {
        display: contents;
    }
    .warranty-list-buttons.bulk {
        display: inherit;
    }

    .warranty-bulk-item-percentage,.warranty-bulk-item-price {
        margin-right: 0px;
    }

}

.create-warranty-button-wrapper {
    margin-bottom: 10px;
}

.create-warranty-button {
    margin-right: 10px;
}

.warranty-bulk-tag-count {
    margin-top: 3px;
}

.warranty-bulk-tag-number {
    font-weight: 600;
}



.remove-bulk-warranty  {
    margin-right: 16px;
}

.in-progress-warranty-wrapper {
    display: flex;

}

.in-progress-warranty{
    margin-left: 5px;
}

.warranty-card-wrapper {
    min-height: 300px;
    display: flex;
    align-items: center;
}

.warranty-card-wrapper.warranty-loaded {
    display: block;
    margin-bottom: 50px;
}