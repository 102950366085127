.app-v2-wizard-wrapper {
  height: 100%;
}

.app-v2-wizard {
  width: 100%;
}

.wizard-step {
  padding: 10px 20px 0px 20px;
  height: 100%;
}

  @media (max-width: 720px) {
    .wizard-step {
      height: calc(100% - 83px);
        position: relative;
    }
    .app-v2-wizard {
      height: 100%;
    }
  }

.wizard-stages {

  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: solid 1px #e5e2e2;

  .wiz-item:last-child {
    .stage-wrapper {
      .circle-wrapper {
        .line {
          border: none;
        }
      }
    }
  }

  .stage-wrapper {
    display: inline-block;
    width: 100%;
    position: relative;

    .circle-wrapper {

      width: 100%;
      position: relative;
      display: block;

      .line {
        position: absolute;
        border-top: 2px solid;
        top: 50%;
        width: 100%;
      }
    ;
    }
  }

  .future, .current {
    .stage-wrapper {
      .circle-wrapper {
        .line {
          border-color: #a4a4a4;
        }
      }
    }

  }

  @media (max-width: 720px) {
    .current {
      .stage-wrapper {
        .circle-wrapper {
          .line {
            display: none;
          }
        }
      }

    }
  }

  .past {
    .stage-wrapper {
      .circle-wrapper {
        .line {
          border-color: #49ac94;
        }
      }
    }

  }

  span {
    width: 20%;
    text-align: center;
  }

  .circle {
    border-radius: 50%;
    padding: 10px 15px;
  }

  .past {
    .circle {
      background-color: #4fb99f;
      color: white;
    }

    .text {
      color: #a4a4a4;
      display: flex;
      margin-top: 10px;
      width: 100%;
      justify-content: center;
    }
  }

  @media (max-width: 720px) {
    .past {
      display: none;
    }
  }

  .current {
    .circle {
      background-color: #02674e;
      color: white;
    }
    .text {
      color: black;
      display: flex;
      margin-top: 10px;
      width: 100%;
      justify-content: center;
    }
  }

  .future {
    .circle {
      background-color: #e5e2e2;
      color: #a4a4a4;
    }

    .line {
      border-color: red;
    }
    .text {
      color: #a4a4a4;
      display: flex;
      margin-top: 10px;
      width: 100%;
      justify-content: center;
    }
  }

  @media (max-width: 720px) {
    .future {
      display: none;
    }
  }
}

.app-v2-wizard-next-btn {
  background-color: #008060;
  color: white;
  cursor: pointer;
  font-size: 25px;
  display: inline;
  padding: 10px;
  border-radius: 11px;
  border: none;
  width: 350px;
  display: flex;
  position: relative;
  height: 65px;
  justify-content: center;
  align-items: center;
}

.app-v2-wizard-next-btn:hover {
  background-color: #008e6e;
}

.nav-buttons-wrapper {
  display: flex;
  align-items: center;
  padding: 11px 0px 22px 20px;
  width: 100%;
  background-color: white;
  border-top: 1px solid #e7e7e7;
  position: absolute;
  bottom: 0;
  left: 0;

  .action-btns-wrapper {
    display: flex;
    align-items: center;
    width: 100%;

    .plain-action-btns-wrapper {
      display: flex;
      margin-left: auto;
      color: #6e6e6e;
    }
  }

  .status-text {
    font-size: 20px;
  }
}

@media (max-width: 720px) {
  .nav-buttons-wrapper {
    flex-direction: column;
    width: 100%;
    padding: 40px;

    .app-v2-wizard-next-btn {
      width: 100%;
    }

    .app-v2-wizard-back-btn {
      width: 100%;
      margin: 0;
      margin-bottom: 5px;
    }
  }
}

.app-v2-wizard-next-btn:before {
  content: "\2192";
  position: absolute;
  right: 12px;
}

.app-v2-wizard-back-btn {
  background-color: white;
  color: #202020;
  cursor: pointer;
  font-size: 25px;
  padding: 10px;
  border-radius: 11px;
  width: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: 1px solid #a4a4a4;
  text-align: center;
  height: 65px;
  margin-right: 10px;
}

.app-v2-wizard-get-started .skip {
  color: black;
  cursor: pointer;
  font-size: 20px;
  font-weight: 400;
  margin-left: 10px;
}

@media (max-width: 720px) {
  .app-v2-wizard-get-started .skip {
    margin-left: 0;
    margin-top: 10px;
    color: #02674e;
    text-decoration: underline;
  }
}

.app-v2-wizard-get-started {

  height: 100%;
  background-image: url(https://public.zoorix.com/images/getting-started-wizard.png);
  background-repeat: no-repeat;
  padding-top: 30px;
  background-position: right;
  background-size: contain;
  margin-left: 30px;

  .title {
    font-size: 54px;
    font-weight: 800;
    line-height: 1.22;
    color: #138e70;
  }

  .subtitle {
    font-size: 37px;
    line-height: 1.23;
    max-width: 570px;
    font-weight: 200;
    margin-top: 10px;
  }
}

.app-v2-wizard-select-offer-type .offers {

  .bundle-select {
    .bundle-offer-type {
      position: relative;
      padding: 4px 0px 4px 25px;

      .selected {
        background-color: white;
      }

      .sub-offer-type {
        padding: 0px 0px 0px 0px;
      }

      .checkbox {
        position: absolute;
        left: -8px;
        top: -5px;
        label {
          height: 16px;
          width: 16px;
        }

        label:after {
          height: 5px;
          left: 2px;
          top: 4px;
          width: 10px;
        }
      }
    }
  }

  .offer-type-block {
    position: relative;
    padding: 10px 15px 15px 40px;
    border-radius: 18px;
    box-shadow: inset 0 0 10px 0 rgba(0, 0, 0, 0.16);
    margin-bottom: 10px;

    .round {
      position: absolute;
      left: 8px;
      top: 10px;
    }

    .round label {
      background-color: #fff;
      border: 1px solid #00806080;
      border-radius: 50%;
      cursor: pointer;
      height: 20px;
      left: 0;
      position: absolute;
      top: 0;
      width: 20px;
    }

    .round label:after {
      border: 2px solid #fff;
      border-top: none;
      border-right: none;
      content: "";
      height: 6px;
      left: 3px;
      opacity: 0;
      position: absolute;
      top: 5px;
      transform: rotate(-45deg);
      width: 12px;
    }

    .round input[type="checkbox"] {
      visibility: hidden;
    }

    .round input[type="checkbox"]:checked + label {
      background-color: #008060;
      border-color: #66bb6a;
    }

    .round input[type="checkbox"]:checked + label:after {
      opacity: 1;
    }

    .container {
      margin: 0 auto;
    }

    .blocks-video-wrapper {
      display: flex;

      .blocks-wrapper {
        width: 100%;
      }

      .video-block {
            margin-left: auto;

            >span {
              cursor: pointer;
            }
      }

    }

  }

  .selected {
    background-color: #effef8;
    border-radius: 18px;
  }

  .not-selected {
    cursor: pointer;
    background-color: white;
  }
}

.app-v2-wizard-select-page {

  padding-bottom: 10px;
  height: 100%;

  .drawings-wrapper {
    overflow: hidden;
    margin-top: 50px;
  }

  .wizard-select-page-title {
    font-size: 30px;
    font-weight: bold;
    line-height: 1.23;
  }

  .wizard-select-page-subtitle {
    margin-top: 5px;
    margin-bottom: 20px;
    font-size: 20px;
  }

  .page-box {
    border-radius: 18px;
  }

  .page-box-text {
    width: max-content;
  }

  .select-page-box {
    grid-template-columns: 1fr 1fr;
    display: grid;
    grid-template-rows: auto;
    max-width: 800px;

    .checkbox {
      pointer-events: none;
      position: absolute;
      top: calc(50% - 10px);
      transform: translate(-50%, 0);
      left: 18px;

      label {
        height: 16px;
        width: 16px;
      }

      label:after {
        height: 5px;
        left: 2px;
        top: 4px;
        width: 10px;
      }
      .round {

      }

      .round label {
        background-color: #fff;
        border: 1px solid #00806080;
        border-radius: 50%;
        cursor: pointer;
        height: 20px;
        left: 0;
        position: absolute;
        top: 0;
        width: 20px;
      }

      .round label:after {
        border: 2px solid #fff;
        border-top: none;
        border-right: none;
        content: "";
        height: 6px;
        left: 3px;
        opacity: 0;
        position: absolute;
        top: 5px;
        transform: rotate(-45deg);
        width: 12px;
      }

      .round input[type="checkbox"] {
        visibility: hidden;
      }

      .round input[type="checkbox"]:checked + label {
        background-color: #008060;
        border-color: #66bb6a;
      }

      .round input[type="checkbox"]:checked + label:after {
        opacity: 1;
      }

      .container {
        margin: 0 auto;
      }

    }
  }

  @media (max-width: 720px) {

    .select-page-box {
      grid-template-columns: 1fr;
    }
  }

  .page-box {
    padding: 10px 10px 10px 40px;
    margin-right: 10px;
    margin-bottom: 10px;
    position: relative;
    max-width: 430px;
    min-height: 151px;
    display: flex;

    .page-box-details {
      display: flex;
      align-items: center;
      height: 100%;
      width: 100%;

      .page-box-image {
        margin-left: auto;
        max-width: 140px;
      }

    }
  }
  .selected {
    cursor: pointer;
    border: 1px solid black;
    background-color: #effef8;
  }

  .not-selected {
    cursor: pointer;
    border: solid 1px #a4a4a4;
    background-color: white;
  }
}

 @media (max-width: 720px) {

    .app-v2-wizard-config-offer {

      .nav-buttons-wrapper { position: initial;}
    }

    .app-v2-wizard-select-trigger {
        .nav-buttons-wrapper { position: initial;}

    }
 }

.app-v2-wizard-select-trigger, .app-v2-wizard-config-offer {

  padding-bottom: 10px;

  .drawings-wrapper {
    margin-top: 0px;
    overflow: hidden;
  }

  .wizard-select-trigger-title {
    font-size: 30px;
    font-weight: bold;
    line-height: 1.23;
  }

  .wizard-select-trigger-subtitle {
    margin-top: 5px;
    margin-bottom: 20px;
    font-size: 20px;
  }

  .select-trigger-wrapper {
    display: flex;

    .select-trigger-item-wrapper {

      position: relative;

      .select-trigger-item {
        width: 240px;
        height: 203px;
        margin-right: 10px;
        border-radius: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .trigger-text {
          margin-bottom: 10px;
          max-width: 130px;
          text-align: center;
        }
      }

      @media (max-width: 720px) {

        .select-trigger-item {
          margin-bottom: 15px;
        }

      }
      .checkbox {
        pointer-events: none;
      }

      .round {
        position: absolute;
        left: 8px;
        top: 10px;
      }

      .round label {
        background-color: #fff;
        border: 1px solid #00806080;
        border-radius: 50%;
        cursor: pointer;
        height: 20px;
        left: 0;
        position: absolute;
        top: 0;
        width: 20px;
      }

      .round label:after {
        border: 2px solid #fff;
        border-top: none;
        border-right: none;
        content: "";
        height: 6px;
        left: 3px;
        opacity: 0;
        position: absolute;
        top: 5px;
        transform: rotate(-45deg);
        width: 12px;
      }

      .round input[type="checkbox"] {
        visibility: hidden;
      }

      .round input[type="checkbox"]:checked + label {
        background-color: #008060;
        border-color: #66bb6a;
      }

      .round input[type="checkbox"]:checked + label:after {
        opacity: 1;
      }

      .container {
        margin: 0 auto;
      }

    }

  }

  @media (max-width: 720px) {
    .select-trigger-wrapper {
      flex-direction: column;
      align-items: center;
    }
  }

  .selected {
    border: 1px solid black;
    background-color: #effef8;
  }

  .not-selected {
    cursor: pointer;
    border: solid 1px #a4a4a4;
    background-color: #fff;
  }

  .open-modal-btn-wrapper {

    margin-top: 20px;
    min-height: 100px;

    .open-modal-btn {
      cursor: pointer;
      width: 364px;
      padding: 18px 22px 17px 0;
      border-radius: 11px;
      border: solid 1px #02674e;
      display: flex;
      justify-content: center;
      font-size: 18px;
      font-weight: 500;
      position: relative;
    }

    .open-modal-btn:before {
      content: "\2192";
      position: absolute;
      right: 12px;
      color: #02674e;
    }

    .open-modal-btn.invalid {

      border-color: red;
    }

  }

}

.app-v2-wizard-select-offer-type .offers {
  .inner-block {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
    background-color: #fff;
    padding: 1px 7px;
    margin: 7px 0px 0px 5px;
  }

  .main-block .title {
    font-weight: bold;
  }

  .inner-block .strong {
    font-weight: bold;
    color: #239a7d;
  }


}

.app-v2-wizard-selected-product {
  cursor: pointer;
  background-color: #f5f5f5;
}

.app-v2-wizard-not-selected-product {
  cursor: pointer;
  background-color: white;
}

.app-v2-wizard-selected-collection {
  cursor: pointer;
  background-color: #f5f5f5;
}

.app-v2-wizard-not-selected-collection {
  cursor: pointer;
  background-color: white;
}

.search-item {
  padding: 10px 40px;
  border-radius: 8px;
  margin-top: 2px;
  display: flex;
  place-items: center;
  position: relative;

  .checkbox {
    pointer-events: none;
    position: absolute;
    top: calc(50% - 10px);
    transform: translate(-50%, 0);
    left: 18px;
    width: 21px;

    label {
      height: 16px;
      width: 16px;
    }

    label:after {
      height: 5px;
      left: 2px;
      top: 4px;
      width: 10px;
    }
    .round {

    }

    .round label {
      background-color: #fff;
      border: 1px solid #00806080;
      border-radius: 50%;
      cursor: pointer;
      height: 20px;
      left: 0;
      position: absolute;
      top: 0;
      width: 20px;
    }

    .round label:after {
      border: 2px solid #fff;
      border-top: none;
      border-right: none;
      content: "";
      height: 6px;
      left: 3px;
      opacity: 0;
      position: absolute;
      top: 5px;
      transform: rotate(-45deg);
      width: 12px;
    }

    .round input[type="checkbox"] {
      visibility: hidden;
    }

    .round input[type="checkbox"]:checked + label {
      background-color: #008060;
      border-color: #66bb6a;
    }

    .round input[type="checkbox"]:checked + label:after {
      opacity: 1;
    }

    .container {
      margin: 0 auto;
    }

  }

  span {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 5px;
  }

  img {
    margin-right: 10px;
  }

  .bottom-border {
    border-bottom: 1px solid #f4f1f1;
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
  }

}

.search-item:hover {
  background-color: #f5f5f5;
}

.app-v2-wizard-selected-tag {
  cursor: pointer;
  background-color: #f5f5f5;
}

.app-v2-wizard-not-selected-tag {
  cursor: pointer;
  background-color: white;
}

@media (max-width: 720px) {
  .wizard-stages {
    height: 80px;
    margin-top: 10px;
  }
}

@media (max-width: 720px) {
  .app-v2-wizard-get-started {
    padding-top: 10px;
    background-position: bottom;
  }
}

.app-v2-wizard-select-offer-type {
  .app-v2-wizard-select-offer-type-content {
    .select-offer-type-offers-help-wrapper {
      display: flex;
      flex-direction: row;

      .offer-image-preview-wrapper {
        width: 600px;
        border: 1px solid #dadada;
        margin: 3px 15px;
        border-radius: 10px;
        padding: 10px 0;

        .offer-image-preview {
            height: 100%;
            width: 100%;
            background-size: contain;
            background-repeat: no-repeat;
            border-radius: 10px;
            background-position: center;
            max-width: 360px;
            margin: 0 auto;
        }
      }

      @media (max-width: 720px) {
        .offer-image-preview-wrapper {
          display: none;
        }
      }

      .offers {
        width: 100%;
      }

      .nav-buttons-wrapper { position: initial;}

    }

    .select-offer-type-header {
      display: flex;
      align-items: center;

     .select-offer-type-nav-wrapper {
        margin-left: auto;
     }

     .upper-nav-buttons-wrapper {
      margin-left: auto;
      .app-v2-wizard-next-btn {
        &.app-v2-wizard-upper-next-btn {
          width: 200px;
          height: 50px;
        }

        @media (max-width: 720px) {
            &.app-v2-wizard-upper-next-btn {
              display: none;
            }
        }

      }
     }
    }

    .need-help-next-wrapper {

      display: flex;

      .nav-buttons-wrapper {
          margin-left: auto;
          width: auto;
              border: none;
                  margin-bottom: auto;

      }

     .need-help-drawing-wrapper {

      position: relative;
      .contact-support {
        display: none;

      }
    }

    }

    @media (max-width: 720px) {
    .need-help-next-wrapper {
        flex-direction: column;
        .nav-buttons-wrapper {
          margin: 0;
          width: 100%;
      }
    }

    }



    .drawings-wrapper {

          overflow: hidden;


      @media (max-width: 720px) {
        .cat-ball {
          width: 100%;
        }
      }

    }

    .need-help-wrapper {
      margin-top: 00px;
      margin-left: 20px;
      margin-bottom: 10px;

      .need-help-title {
        margin-bottom: 5px;
        font-size: 23px;
        font-weight: 500;
        cursor: pointer;
      }
      .need-help-subtitle {
        margin-bottom: 5px;

      }
      .need-help-call-to-action {
        color: #008060;
        font-weight: 600;
        position: relative;
        width: 95px;
        display: block;
        cursor: pointer;
        padding-bottom: 10px;
      }

      .need-help-call-to-action:before {
        content: "\2192";
        position: absolute;
        right: 2px;
      }
    }
  }

  .select-offer-type-title {
    font-size: 30px;
    font-weight: bold;
    line-height: 1.23;

  }

  .select-offer-type-subtitle {
    margin-top: 5px;
    margin-bottom: 20px;
    font-size: 20px;

  }
}

.hidden {
  display: none;
}

.select-something-wrapper {
  min-height: 20px;
}

.ant-modal-footer .ant-btn.ant-btn-primary {
  margin-left: 8px;
  width: 152px;
  height: 48px;
  border-radius: 11px;
  background-color: #008060;
}

.ant-modal-footer .ant-btn.ant-btn-default {
  margin-left: 8px;
  width: 152px;
  height: 48px;
  border-radius: 11px;
  background-color: white;
}

.ant-modal-content input {
  position: relative;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, .65);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 7px;
  transition: all .3s;
  display: inline-flex;
}

.ant-modal-content {
  height: 500px;
  .spinner-wrapper {
    display: flex;
    display: flex;
    height: 300px;
    align-items: center;
    justify-content: center;
  }
}

.video-modal {

  .ant-modal-content {
      height: inherit;
   }

}

@media (min-width: 720px) {


  .video-modal {

    /*max-width: 50% !important;*/

    .ant-modal-content {
      height: inherit;
    }

  }

}




.ant-modal-content .selected-items-wrapper {
  display: flex;
  padding: 5px 3px;
  width: 100%;
  overflow-y: scroll;
}

.ant-modal-content .selected-item {
  min-width: 110px;
  white-space: nowrap;
  overflow: hidden;
  display: flex;
  text-overflow: ellipsis;
  margin-right: 5px;
  background: #eff0f3;
  padding: 5px 10px;
  border-radius: 7px;
}

.ant-modal-content .selected-item > .selected-item-title {
  max-width: 82px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ant-modal-content .selected-item > .selected-item-x {
  margin-left: auto;
  cursor: pointer;

}

.ant-modal-content .ant-btn:hover, .ant-btn:focus {
  color: #008060;
  border-color: #008060;
  background: #fff;
}

.ant-modal .ant-modal-footer {
  position: absolute;
  bottom: -60px;
  left: 0;
  width: 100%;
  background: white;
}

.search-items-wrapper {
  height: 330px;
  display: block;
  overflow: scroll;
}

@media (max-width: 720px) {
  .ant-modal {
    width: 100% !important;
  }
}

.app-v2-wizard .ant-input-number-handler-wrap {
  opacity: 1;
}

.app-v2-wizard .ant-input-number {
  outline: none;
}

.app-v2-wizard .ant-input-number:hover {
  border: 1px solid #7b7b7b;
  box-shadow: none;
}

.app-v2-wizard .ant-input-number:focus {
  border: 1px solid #7b7b7b;
  box-shadow: none;
}

.app-v2-wizard .ant-input-number-input-wrap {
  outline: none;
  box-shadow: none;

}

.app-v2-wizard .ant-input-number-focused {
  outline: none;
  box-shadow: none;
  border: 1px solid #7b7b7b;
}

.app-v2-wizard .ant-input-number-group-addon:first-child {
  border-right: 0;
  min-width: 81px;
}

.app-v2-wizard .field-wrapper {
  margin-right: 10px;
  position: relative;

  .ant-input-number-group-addon {
    min-width: 80px;
  }

  .round {
    position: absolute;
    left: 8px;
    top: -9px;
  }

  @media (max-width: 720px) {
    .round {
      position: absolute;
      left: 0px;
      top: 11px;
    }
  }

  .round label {
    background-color: #fff;
    border: 1px solid #00806080;
    border-radius: 50%;
    cursor: pointer;
    height: 20px;
    left: 0;
    position: absolute;
    top: 0;
    width: 20px;
  }

  .round label:after {
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: "";
    height: 6px;
    left: 3px;
    opacity: 0;
    position: absolute;
    top: 5px;
    transform: rotate(-45deg);
    width: 12px;
  }

  .round input[type="checkbox"] {
    visibility: hidden;
  }

  .round input[type="checkbox"]:checked + label {
    background-color: #008060;
    border-color: #66bb6a;
  }

  .round input[type="checkbox"]:checked + label:after {
    opacity: 1;
  }

  .container {
    margin: 0 auto;
  }

}

@media (max-width: 720px) {
  .app-v2-wizard .field-wrapper {
    margin-bottom: 10px;
  }
}

.app-v2-wizard-config-volume {
  .volume-tier-wrapper {
    padding: 15px 18px 10px 18px;
    border-radius: 10px;
    border: 1px solid #e7e7e7;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    max-width: 870px;

    .vd-tier-most-popular {
      margin-left: 27px;
      cursor: pointer;
      width: fit-content;
    }

  }

  .add-tier {
    background-color: white;
    color: #202020;
    cursor: pointer;
    font-size: 17px;
    padding: 10px;
    border-radius: 11px;
    width: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border: 1px solid #a4a4a4;
    text-align: center;
    height: 43px;
    margin-top: 10px;
  }

  .vd-tier-delete {
    margin-left: auto;
    cursor: pointer;
  }

  @media (max-width: 720px) {
    .volume-tier-wrapper {
      align-items: initial;
      flex-direction: column;
      margin-bottom: 10px;
      position: relative;
    }

    .vd-tier-delete {
      position: absolute;
      bottom: 10px;
      right: 13px;
    }

  }
}

.offer-bundle-items-modal {

  .search-item {
    padding: 10px;
  }

  .bundle-selected-item {
    white-space: nowrap;
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
    margin-top: 4px;
    padding: 10px 40px;
    position: relative;
    border: 1px solid #e5e5e5;
  }

  .bundle-selected-item-title {
    margin-left: 7px;
  }

  .checkbox {
    pointer-events: none;
    position: absolute;
    top: calc(50% - 10px);
    transform: translate(-50%, 0);
    left: 7px;

    label {
      height: 16px;
      width: 16px;
    }

    label:after {
      height: 5px;
      left: 2px;
      top: 4px;
      width: 10px;
    }
    .round {

    }

    .round label {
      background-color: #fff;
      border: 1px solid #00806080;
      border-radius: 50%;
      cursor: pointer;
      height: 20px;
      left: 0;
      position: absolute;
      top: 0;
      width: 20px;
    }

    .round label:after {
      border: 2px solid #fff;
      border-top: none;
      border-right: none;
      content: "";
      height: 6px;
      left: 3px;
      opacity: 0;
      position: absolute;
      top: 5px;
      transform: rotate(-45deg);
      width: 12px;
    }

    .round input[type="checkbox"] {
      visibility: hidden;
    }

    .round input[type="checkbox"]:checked + label {
      background-color: #008060;
      border-color: #66bb6a;
    }

    .round input[type="checkbox"]:checked + label:after {
      opacity: 1;
    }

    .container {
      margin: 0 auto;
    }
  }

}

.app-v2-wizard-config-bundle {

  .selected-bundle-item-title {
    margin-left: 7px;
  }

  .selected-bundle-items-container {
    display: flex;
  }

  .delete-bundle-item {
    margin-left: auto;
    cursor: pointer;
    width: 20px;
  }
  .selected-bundle-items {
    padding: 10px;
    border: 1px solid #e3e3e3;
    border-radius: 7px;
    display: flex;
    margin-top: -1px;
    width: 100%;
    align-items: center;
  }

  .selected-bundle-items-wrapper {
    margin-top: 20px;
  }

  .select-products {
    background-color: white;
    color: #202020;
    cursor: pointer;
    font-size: 20px;
    padding: 10px;
    border-radius: 11px;
    width: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border: 1px solid #a4a4a4;
    text-align: center;
    height: 45px;
    margin-top: 10px;

    &.invalid {
      border-color: red;
    }
  }

  .field-wrapper {
    border: 1px solid #e2e2e2;
    display: block;
    padding: 15px;
    width: fit-content;
    border-radius: 12px;
  }

  @media (max-width: 720px) {
    .field-wrapper {
      width: 100%;

      .ant-input-number-group-wrapper {
        width: 100%;
      }
    }
  }
}

.app-v2-wizard-test-offer {

  .nav-buttons-wrapper {
    position: fixed;
  }

  background-size: cover;
  background-image: url(https://public.zoorix.com/images/wizard-done.png);
  position: relative;
  height: calc(100% - 100px);
  background-repeat: no-repeat;

  .progress-spinner {display: none;}

  .jumping-man {
    background-image: url(https://public.zoorix.com/images/jumping-man.svg);
    background-size: contain;
    position: absolute;
    right:180px;
    top: -55px;
    background-repeat: no-repeat;
    height: 400px;
    width: 300px;
    margin-left: 30px;


  }

  .jumping-man{

    animation-name: floating;

    animation-duration: 3s;

    animation-iteration-count: infinite;

    animation-timing-function: ease-in-out;

  }


  @keyframes floating {

    from { transform: translate(0,  0px); }

    50%  { transform: translate(0, 15px); }

    to   { transform: translate(0, -0px); }

  }

  .text-offer-title-wrapper {

    padding: 20px 40px 0px 20px;
    background-color: #ffffffbd;
    width: fit-content;

    .test-offer-title {
      font-size: 54px;
      font-weight: bold;
      line-height: 1.2;
      color: #138e70;

    }

    .test-offer-subtitle {
      font-size: 26px;
      font-weight: bold;
      line-height: 1.23;
      color: #138e70;
    }

    .test-offer-desc {
      margin-top: 5px;
      margin-bottom: 20px;
      font-size: 20px;
    }

    .zrx-offer-creation-timeout-call-to-action {
      border: 1px solid rgb(198, 198, 198);
      width: fit-content;
      margin-top: 10px;;
      padding: 5px 20px;
      border-radius: 10px;
      cursor: pointer;
      color: #3f3c3c;
    }
  }

  .view-percentage {
    margin-left: 7px;
    margin-right: 7px;
    font-size: 20px;
  }

  .offer-progress {
    display: flex;
    align-items: center;
  }

  .action-buttons-wrapper {
    display: flex;
    position: absolute;
    bottom: 0;
  }

  .action-btn {
    cursor: pointer;
    font-size: 25px;
    padding: 10px;
    border-radius: 11px;
    width: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border: 1px solid #a4a4a4;
    text-align: center;
    height: 65px;
    margin-right: 10px;

    &.view-offer-btn {
      width: 180px;
      margin-left: auto;
      border: none;
      margin: 0;
    }

    &.creating-offer-btn {

        min-width: 500px;

        @keyframes placeHolderShimmer {
          0% {
            background-position: -800px 0
          }
          100% {
            background-position: 800px 0
          }
        }

        &.animated-background {
          animation-duration: 2s;
          animation-fill-mode: forwards;
          animation-iteration-count: infinite;
          animation-name: placeHolderShimmer;
          animation-timing-function: linear;
          background-color: #f6f7f8;
          /*background: linear-gradient(to right, #eeeeee 8%, #dadada 18%, #eeeeee 33%);*/
          background: linear-gradient(to right, #f4f4f4 8%, #f5f5f5 18%, #fcfcfc 33%);
          background-size: 800px 104px;
          height: 70px;
          position: relative;
        }
    }

    &.done-btn {
      background-color: white;
      color: #202020;
    }

    &.abort-btn {
      background-color: white;
      border-color: #dfdfdf;
      width: 100px;
      border: none;
      margin-left: auto;
    }

    &.create-more-btn {
      /*background-color: #008060;*/
      background-color: white;
      /*color: white;*/
      color: #202020;


      &.hidden-btn {
        visibility: hidden;
      }
    }

    &.publish-btn {

      background-color: #008060;
      color: white;
      min-width: 500px;

      .Polaris-Spinner {
          display: flex;
       }

      .Polaris-Spinner svg {
        fill: white
      }
    }

  }
}

.enable-zoorix-modal {

  .ant-modal-content {
    height: 600px;
  }

  .zrx-global-configurations-popoup {
    height: 100%;
    max-height: 500px;
    position: relative;

    .zrx-global-configurations-popoup-images-wrapper {
      width: 380px;
      border: 1px solid rgb(221, 221, 221);
      padding: 10px;
      margin: 0 10px;
      border-radius: 10px;
    }

    .zrx-global-configurations-popoup-done-btn {
      width: fit-content;
      padding: 5px 20px;
      border: 1px solid rgb(142 142 142);
      margin-top: 15px;
      border-radius: 10px;
      cursor: pointer;
      margin-left: auto;
      padding: 10px 30px;
      font-weight: 400;
      position: absolute;
      bottom: 0;
      right: 0;
    }

    .enable-zoorix-btn-description {
      /*font-weight: 500;*/
    }

    .offer-placement-method-wrapper {
          position: relative;
          padding: 11px 17px 11px 17px;
          border: 1px solid rgb(221, 221, 221);
          border-radius: 10px;
          margin-top: 15px;

          .choose-offer-placement-title-wapper { display: flex;}

          .open-class {
            width: 10px;
            display: block;
            cursor: pointer;
            transform: rotate(90deg);
            margin-right: 5px;

          }

          .close-class {
            width: 10px;
            display: block;
            cursor: pointer;
            margin-right: 5px;
          }

          .setup-app-blocks-btn-wrapper {
              height: 100%;
              display: flex;
              width: 100%;
              position: relative;
              /*padding: 15px;
              border: 1px solid rgb(221, 221, 221);
              padding-left: 10px;*/
              margin-top: 7px;
              border-radius: 10px;
              align-items: center;
             .setup-app-blocks-btn {
                  color: black;
                  cursor: pointer;
                  font-size: 17px;
                  padding: 10px;
                  border-radius: 11px;
                  width: 220px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  position: relative;
                  border: 1px solid #a4a4a4;
                  text-align: center;
                  height: 40px;
                  margin-right: 10px;
                  align-self: center;

             }
          }

       .offer-placement-method-description {
         font-size: 13px;
         color: #787878;
       }

       .choose-offer-placement-title {
         cursor: pointer;
      }

      .page-box {
        padding: 8px 20px 10px 40px;
        border: 1px solid rgb(221, 221, 221);;
        border-radius: 10px;
        position: relative;
        display: flex;
        flex-direction: column;
        cursor: pointer;
      }

      .page-box:last-child {
        margin-top: 7px;
      }
    }

    .checkbox {
      pointer-events: none;
      position: absolute;
      left: 1px;
      top: 1px;
      label {
        height: 16px;
        width: 16px;
      }

      label:after {
        height: 5px;
        left: 2px;
        top: 4px;
        width: 10px;
      }
      .round {

      }

      .round label {
        background-color: #fff;
        border: 1px solid #00806080;
        border-radius: 50%;
        cursor: pointer;
        height: 20px;
        left: 7px;
        position: absolute;
        top: 10px;
        width: 20px;
      }

      .round label:after {
        border: 2px solid #fff;
        border-top: none;
        border-right: none;
        content: "";
        height: 6px;
        left: 3px;
        opacity: 0;
        position: absolute;
        top: 5px;
        transform: rotate(-45deg);
        width: 12px;
      }

      .round input[type="checkbox"] {
        visibility: hidden;
      }

      .round input[type="checkbox"]:checked + label {
        background-color: #008060;
        border-color: #66bb6a;
      }

      .round input[type="checkbox"]:checked + label:after {
        opacity: 1;
      }

      .container {
        margin: 0 auto;
      }

    }

  }

  .ant-btn.ant-btn-primary {
    display: none;
  }

  .enable-zoorix-wrapper {

    display: flex;
    flex-direction: row;
    border: 1px solid rgb(221, 221, 221);
    padding: 15px;
    border-radius: 10px;

    .enable-zoorix-how-to-image-wrapper {
      min-height: 100%;
      width: 262px;
    }

      @media (max-width: 720px) {
        .enable-zoorix-how-to-image-wrapper {
          display: none;
        }
      }

    .enable-zoorix-how-to-image {
      width: 100%;
      height: 100%;
      background-size: cover;
      background-image: url(https://public.zoorix.com/admin/app-embed-preview-big.png);
    }

    .enable-zoorix-btn-wrapper {
      height: 100%;
      display: flex;
      flex-direction: column;
      width: 100%;
      position: relative;

      align-items: center;
      min-height: 180px;
      justify-content: center;
      

      .enable-zoorix-btn, .disable-zoorix-btn {
        background-color: #008060;
        color: white;
        cursor: pointer;
        font-size: 17px;
        padding: 10px;
        border-radius: 11px;
        width: 220px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        border: 1px solid #a4a4a4;
        text-align: center;
        height: 60px;
        margin-right: 10px;
        align-self: center;
        margin-bottom: 10px;
        .Polaris-Spinner {
          display: flex;
        }
        .Polaris-Spinner--sizeSmall {
          svg {
            height: 2.5rem;
            width: 2.5rem;
          }
        }

      }

      .enable-zoorix-btn {
        .Polaris-Spinner {
            svg { fill: white; }
        }
      }

      .disable-zoorix-btn {
          background-color: white;
          color: #a4a4a4;
          border-color: gray;
          .Polaris-Spinner {
            svg { fill: #d6d6d6; }
        }
      }

      .enable-zoorix-btn:hover {
        background-color: #008e6a;
      }
    }
  }

  .ant-modal-body {
    height: 100%;
  }

}

@media (max-width: 720px) {
  .app-v2-wizard-test-offer {
    background: none;
    position: fixed;

    .jumping-man {
      left: 25%;
      top: 40%;
      width: 15%;
  }

    .action-btn {
      margin-bottom: 5px;
      width: inherit;
    };
  }
}


.app-v2-wizard-mnm-offer {
  .wizard-mnm-offer-add-collection {
    background-color: white;
    color: #202020;
    cursor: pointer;
    font-size: 20px;
    padding: 10px;
    border-radius: 11px;
    width: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border: 1px solid #a4a4a4;
    text-align: center;
    height: 45px;
    margin-top: 10px;

    &.invalid {
      border-color: red;
    }

  }
  .wizard-mnm-offer-collection-title {
    display: block;
    font-weight: 500;
    margin-bottom: 2px;
  }

  .wizard-mnm-offer-collection-row {
    border: 1px solid #dbdbdb;
    margin-top: 4px;
    padding: 10px;
    border-radius: 5px;
  }

  .wizard-mnm-offer-collection-details {
    display: flex;
    align-items: center;
  }


  span.wizard-mnm-offer-delete-collection {
    margin-left: 7px;
    cursor: pointer;
  }
}


.need-help-after-skip {

    .need-help-book-meeting-btn {
      background-color: #008060;
      color: white;
      cursor: pointer;
      font-size: 25px;
      padding: 10px;
      border-radius: 11px;
      width: 400px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      border: 1px solid #a4a4a4;
      text-align: center;
      height: 65px;
      margin-right: 10px;
  }

  .need-help-after-skip-title {
     font-size: 22px;
     margin-bottom: 18px;
     font-weight: 500;
     text-align: center;
     margin-top: 50px;
  }

  .need-help-continue-to-admin-btn {
    background-color: white;
    cursor: pointer;
    font-size: 19px;
    padding: 10px;
    border-radius: 11px;
    width: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    text-align: center;
    height: 65px;
    margin-right: 10px;
    margin-top: 30px;
    color: #484848;
  }

  .need-help-after-skip-content-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 42px;
  }

}