.padding-top {
    display: block;
    padding-top: 5px;
}

.padding-bottom {
    display: block;
    padding-bottom: 10px;
}

.install-dashboard {
    padding: 30px;
    background-color: #f4f6f8;
    height: 100%;
}

.install-container {
    max-width: 500px;
    margin: 0 auto;
    padding: 25px;
    border-radius: 3px;
    overflow: hidden;
    background-color: white;
    box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15);
    min-height: 300px;
}

.install-welcome-text {
    padding-bottom: 20px;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 1.4rem;
    margin: 0;
}

.install-fields-wrapper {
    display: flex;
}

.install-button {
    margin-left: auto;
    padding-left: 5px;
}

.install-shop-name {
    width: 100%;
}

.install-welcome {
    text-align: center;
}

.install-welcome-image {
    width: 100px;
}
