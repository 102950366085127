.unsubscribed-title {

    max-width: 80%;
    width: 550px;
    border: 4px solid #3B9F55;
    margin: 0 auto;
    text-align: center;
    font-size: 40px;
    font-weight: 500;
    color: #3B9F55;
    padding: 20px;
    margin-top: 50px;
    line-height: 70px;
    padding-bottom: 25px;
}

.change-mind {
    margin-top: 15px;
    text-align: center;
}