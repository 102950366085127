.Polaris-Frame {
  background-color: white !important;
}

.form-card .Polaris-Card {
  padding: 0px 30px 30px;
}

.form-card {
  width: 100%;
}

.form-card .Polaris-Heading {
  margin-bottom: 20px;
}

.chrome-picker {
  margin: 0 auto;
}

.Polaris-Labelled__LabelWrapper {
  margin-top: 8px;
}

.color-title {
  margin-top: 10px;
  margin-bottom: 10px;
}

.Polaris-Card__Header {
  padding-left: 0;
}

.placeHolder {
  height: 150px;
  background-color: red;
}

.widget-placement {
  min-height: 275px;
  position: relative;
  float: right;
  min-width: 560px;
  padding: 20px;
}

.fixed-position {
  position: fixed;
  top: 70px;
}

.stack-layout {
  max-width: 55%;
}

.save-button {
  position: fixed;
  bottom: 10px;
  left: calc(50% - 25px);
  z-index: 50;
}

.save-button.trial {
  /*bottom: 75px;*/
}

@media only screen and (max-width : 900px) {
  .widget-placement {
    visibility: hidden;
    min-height: 0;
    height: 0;
    min-width: 0;
  }

  .stack-layout {
    max-width: 90%;
  }

  /*.save-button {
    left: 50%;
  }*/
}


.field-tip {
  color: #7a7a7a;
  margin-top: 5px;
  font-size: 13px;
}



#save-settings.Polaris-Button.Polaris-Button--primary {
  width: 150px;
  height: 50px;
}

.Polaris-Icon__Svg {
  top: 3px;
}

.location-checkbox {
  padding-left: 25px;
}

.locations-spinner {
  display: flex;
  margin-top: 3px;
  margin-left: -2px;
}

.fetching-locations {
  margin-left: 6px;
}

.main-widget-tooltip {
  stroke: #f9fafb;
  padding-left: 2px;
}

.main-widget-tooltip>span>.Polaris-Icon {
  width: 12px;
}

.refresh-after-add {
  padding-top: 5px;
  display: flex;
  align-content: center;
}

.form-card.warranty-settings textarea {
  max-height: 350px;
}

.incentive-select-wrapper {
  padding: 10px 25px 10px 25px;
}

.incentive-select-wrapper .Polaris-Label__Text {
  font-weight: 600;
  font-size: 1.6rem;
}

@media (max-width: 720px) {
  .incentive-select-wrapper .Polaris-Label__Text {
    font-size: 1.7rem;

  }
}

.selector-card {
  display: flex;
}

.selector-card-title {
  font-weight: 600;
  margin-top: 25px;
}


.selector-card>div {
  width: calc(100% - 200px)
}

.selector-card>div:first-child {
  width: 200px;
  padding-right: 10px;
}

.selector-card.cart-total-selector>div:first-child {
  width: 100%;
}



.Polaris-TextField__Segment>.Polaris-TextField__SpinnerIcon>.Polaris-Icon>svg {
  top: -5px;
}

.fb-pixel-tooltip {
  margin-top: 5px;
  color: #656565;
  font-size: 13px;
}

.selection-logic {
  min-width: 115px;
  margin-left: 8px;
}

.cart-note-field {
  margin-bottom: 10px;
}

.field-tip.refresh-after-add-tip {
  margin-left: 4px;
  line-height: 19px;
}

.cart-drawer-enable-toggle {
  max-width: 401px;
  margin-left: 22px;
}