
.happy-gif {
    margin-top: 30px;
    text-align: center;
  }
  .happy-gif-img {
    padding-top: 40px;
    width: 300px;
      max-width: 100%;
  }
  
  .whohoo {
    text-align: center;
    font-size: 36px;
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 20px;
  
  }
  
  .fa.fa-heart{
    color: #d83242;
  }
  
  .whohoo-text {
    padding: 0 15px;
  
  }
  
  .happy-text {
    max-width: 500px;
    width: 70%;
    margin: 0 auto;
    text-align: center;
    font-weight: 500;
    margin-bottom: 23px;
  }

  .feedback-wrapper {
      padding-top: 30px;
      text-align: center;
      margin: 0 auto;
      width: 90%;
      max-width: 600px;
  }

  .help-us {
      font-size: 24px;
      line-height: 30px;
      font-weight: 600;
      padding-bottom: 30px;
  }

  .asking-feedback {
      font-size: 16px;
      font-weight: 400;
      padding-bottom: 15px;
  }

  .feedback {
      background-color: white;
      padding: 30px 40px 30px 40px;
  }

  .happy-gif-wrapper {
      width: 90%;
      margin: 0 auto;
      background-color: white;
      max-width: 600px;

  }

  .rate-button > button {
      background-color: #4cae4c;
      background: #4cae4c;
      border-color: white;
      box-shadow: none;

  }

  .rate-button > button > .Polaris-Button__Content {
    font-weight: 600;
  }


  .rate-button > button:hover {
    background-color: #3bc43b;
    background: #3bc43b;
    border-color: white;
}

.feedback-sent-wrapper {
    text-align: center;
    font-size: 40px;
    width: 70%;
    margin: 0 auto;
    background-color: white;
    max-width: 600px;
    margin-top: 50px;
    padding-bottom: 10px;
    padding-top: 10px;



}

.feedback-sent-wrapper > img {
    margin-bottom: 15px;
}

.feedback-sent-wrapper > div {
    min-height: 50px;
}

.review-wrapper.landing-page {
    max-width: 600px;
    margin: 0 auto;
}

.feedback-logo-wrapper {
    text-align: center;
}