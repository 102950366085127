#emerald-table {

  table {
    width: 100%;
    //margin: 0 40px 40px 40px;
  }

  table thead tr h1,
  table thead tr h2,
  table thead tr .h1,
  table thead tr .h2,
  table thead tr h3,
  table thead tr .h3,
  table thead tr h4,
  table thead tr .h4 {
    color: #151515;
  }
  table thead tr {
    background-color: #e7e7e7;
  }

  table tbody tr:nth-of-type(even) {
    background: #f3f3f3;
  }
  table td {
    padding: 8px 14px;
    text-align: left;
    font-size: 12px;
  }
  table th {
    padding: 8px 14px;
    text-align: left;
    font-size: 12px;
  }
  table td a,
  table th a {
    font-weight: bold;
    color: #00918a !important;
  }
  table td a:hover,
  table th a:hover,
  table td a:focus,
  table th a:focus {
    color: white !important;
  }
  table th {
    font-weight: 700;
    font-size: 12px;
    line-height: 1.4;
    margin: 0;
    max-width: 94px;
    width: 33%;
    line-height: inherit;
    color: #151515;
  }
  table th.PrimaryFont {
    text-transform: none;
  }


  @media only screen and (max-width: 599px) {
  /*    table {
      margin: 15px 20px !important;
    }*/

    table td a, table th a {
      color: black !important;
      text-decoration: none !important;;
      pointer-events: none !important;
      font-weight: 400;
    }

    table th {
      display: none;
    }
    table td {
      position: relative;
      display: block;
      padding-left: 110px;
    }
    table td:before {
      position: absolute;
      top: 5px;
      left: 10px;
      display: block;
      content: attr(aria-label) ": ";
      font-weight: 600;
    }
    table tbody tr {
      padding: 0px 0;
      display: block;
    }
    table:nth-of-type(odd) {
      background: #f3f3f3;
    }

    table tbody tr:nth-of-type(even) {
      background: white;
    }

    tr > td:nth-child(2) {
      display: none;
    }

    table td {
      padding: 4px 14px;
      padding-left: 130px;
    }
  }
}

#emerald-table table {
  width: 100%;
  background-color: white;
  box-shadow: 0 0 6px 1px #b6babf;
  border-radius: 6px;;
}

#plans-panel {

  table th {
    font-size: inherit;
  }

  table td {
    font-size: inherit;
  }

  #emerald-table {
    padding: 20px 130px 0 130px;
  }
}

.upgrade-pricing-table-wrapper {

  table th {
    font-size: inherit !important;
  }

  table td {
    font-size: inherit !important;
  }
}