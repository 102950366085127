.bundle-page-back {    margin-right: 5px;  }

.bundle-in-page-item {
    border: 1px solid gray;
    padding: 10px;
    display: flex;
    align-items: center;
    background-color: #e2e2e27a;
}

.search-bundle-title {
    margin-top: 10px;
}

.bundle-search-box {
    margin-bottom: 5px;
    display: block;
}

.bundle-search-box .react-search-box-dropdown-list-item {
    height: 60px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
}

.bundle-page-arrows {
    min-width: 45px;
    display: flex;
    justify-content: center;
}

.remove-page-bundle {
    margin-left: auto;
}

.page-of-bundles {
    padding: 10px;
    border: 1px solid #c7c7c7;
    display: flex;
    align-items: center;
}

.navigation-buttons.bundle-pages {
    margin-bottom: 10px;
    margin-right: 5px;
}

.zrx-multi-offer-setup .navigation-button {
    margin-left: 5px;;
}

.page-of-bundles-title {
    min-width: 100px;
    font-weight: bold;
}

.page-of-bundles-details {
    margin-left: auto;
    display: flex;
    align-items: center;
}

.page-of-bundles-edit {
    margin-left: 18px;
}

.page-of-bundles-delete button {
    border: none;
    min-width: 52px;
}

.zrx-multi-offer-page-back {
    float: left;
}

.zrx-multi-offers-edit-page .zrx-navigation-button {
    margin-left: 5px;
    margin-right: 5px;
}

.zrx-multi-offers-search-item {
    display: flex;
    border: 1px solid #b8b8b8;
    padding: 10px;
    margin-bottom: -1px;
    place-items: center;
    max-height: 50px;
}

.zrx-multi-offers-delete {
    margin-left: auto;
}

.zrx-multi-offers-search-items {
    padding: 1px 8px;
}

.pages-search-box-wrapper div {margin: 0;}

.zrx-multi-offers-order-arrows-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 8px;
}