.product-container {
    border-bottom: 1px solid rgb(232, 232, 232);
    border-left: 1px solid rgb(232, 232, 232);
    border-right: 1px solid rgb(232, 232, 232);
    padding: 7px;
    height: 67px;
    position: relative;
    display: flex;
    overflow: hidden;
}

.product-container.green {
    background-color: lightgreen;
}

.product-container:nth-child(2) { border-top: 1px solid rgb(232, 232, 232) }
.source-products.dest .product-container:nth-child(3) { border-top: 1px solid rgb(232, 232, 232) }

.item-image {
    background-size: contain;
    background-repeat: no-repeat;
    height: 100%;
    width: 52px;
    background-position: left center;
}

.collection-item-image {
    background-size: contain;
    background-repeat: no-repeat;
    height: 45px;
    width: 45px;
    margin-right: 10px;
    margin-left: 5px;
}

.item-image.bundle{
    width: 45px;
    height: 45px;
}

.item-title.product {
    display: flex;
    align-self: center;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    max-height: 44px;
}

@media only screen and (max-width : 830px) {
    .item-image {
      width: 20px
    }
    .item-title {
        font-size: 10px;
        vertical-align: inherit !important;
        text-overflow: ellipsis !important;
        display: inline-block !important;
        white-space: nowrap !important;
        padding-left: 5px !important;
        padding-top: 2px !important;
    }

    .product-container {
        height: 40px;
    }

    .product-container .remove-item {
        top: 33% !important;
        right: 7px !important;
        height: 12px;
        width: 12px;
    }
    
  }

.product-container .remove-item {
    cursor: pointer;
    margin-left: auto;
    align-self: center;
}

.product-container.searchable {
    cursor: pointer;
    overflow: hidden;
}

.product-container .caret-up {
    cursor: pointer;
}

.product-container .caret-down {
    cursor: pointer;
}

.frame-wrapper {
    padding: 12px 0px 0px 0px;
}

.navigation {
    padding-bottom:15px;
}

.navigation.product-mapping {
    display: flex;
}

.navigation-button {
    margin-right:10px;
}

.navigation-button.save {
    margin-left: auto;
}

.mapping-item {
    border-bottom: 1px solid rgb(232, 232, 232);
    border-left: 1px solid rgb(232, 232, 232);
    border-right: 1px solid rgb(232, 232, 232);
    padding: 12px;
    position: relative;
    display: flex;
    align-items: center;
}

.mapping-item.hidden {
    display: none;
}

.mapping-item > .remove-item {
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    right: 19px;
    cursor: pointer;
}

.mapping-item:nth-child(2) { border-top: 1px solid rgb(232, 232, 232) }

.mapping-item-name {
    display: inline-block;
}

.mapping-number {
    display: inline-block;
    padding-right: 7px;
}

.item-name {
    font-weight: 500;
    margin-right: 10px;
    display: flex;
    align-items: baseline;

}

.mapping-rule {
    background-color: lightgray;
    margin: 0 2px;
    padding: 2px 5px;
    border-radius: 5px;
    font-size: smaller;
}

.search-products {
    width: 50%;
    float: left;
    padding-right: 20px;
    min-height: 100px;
    min-height: 650px;
}

.search-products.bundle {
    width: 100%;
}

.source-products {
    width: 50%;
    float: left;
    padding: 22px 10px 20px 20px;
    min-height: 100px;
    margin-bottom: 10px;
    border-left: solid 1px lightgrey;
    min-height: 400px;

}

.source-products-title {
    padding-bottom: 5px;
}

.icon {
    width: 50px;
    height: 50px;
    padding: 14px;
}

.item-title {
    padding-left: 10px;
    font-weight: 600;
    overflow: hidden;
}

.item-title.product {  }

.item-title.searchable {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    max-height: 44px;
    align-self: center;
}
.image-wrapper {
}

.mapping-table {
    width: 95%;
    margin: 0 auto;
    padding-top: 20px;
}

.arrows {
    display: inline-grid;
    align-items: center;
    justify-content: center;
    float: left;
    height: 100%;
}

.spinner-wrapper {
    display: table;
    margin: 0 auto;
}

.fetch-spinner {
    padding-left: 20px;
}

.fetch-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 355px;
    font-size: 18px;
}

.fetch-search-wrapper {
    padding-top:10px;
    display: flex;
}

.searching {
    padding-left: 3px;
}

.tip {
    margin-left: 10px;
    stroke: #fafafa;
}

.mapping-tip {
    right: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.mapping-button {
    position: relative;
}

.mapping-button-wrapper {
    margin-right: 10px;
}

.mapping-wrapper {
    padding: 10px;
    border: 1px solid #c7c7c7;
    border-radius: 10px;
    width: fit-content;
}

.mapping-wrapper .play-icon {
    position: relative;
    white-space: nowrap;
}

.mapping-button-wrapper .Polaris-Button {
    width: 190px;
}

.mapping-button-wrapper.global .Polaris-Button {
    /*width: 160px;*/
}

.mapping-button-wrapper.limited .Polaris-Button {
    /*width: 130px;*/
    box-shadow: none;
}

.mapping-button-wrapper.excluded .Polaris-Button {
    /*width: 130px;*/
    box-shadow: none;
}

.mapping-wrapper {
    display: flex;
    align-items: center;
}

.label-wrapper {
    display: flex;
    align-items: center;
    margin-left: 20px;
    max-width: 450px;
    color: #7a7a7a;

}

@media only screen and (max-width : 900px) {
    .label-wrapper {
        display: none;
    }
}

.bold {
    font-weight: 700;
    display: contents;

}

.navigation-wrapper {
    display:flex;
    align-items: center;
    justify-content: left;
}

.filter-wrapper {
    margin-right: 10px;
}

.Polaris-Popover__Content {
    max-height: initial;
}

.search-mapping {
    margin-left: auto;
    display: flex;
    position: relative;
}

.search-mapping-input {
    margin-right: 10px;
    width: 300px;
}

.mapping-search-results {
    position: absolute;
    top: 70px;
    background-color: white;
    z-index: 100;
}

.mapping-search-item {
    padding: 10px;
    display: flex;
    border: 1px solid #ebebeb;
    width: 300px;
    align-items: center;
}

.mapping-search-item.filtered {
    background-color: #ebebeb;
}

.mapping-search-item-image {
    margin-right: 10px;
    border: 1px solid #f6f6f6;
}

.where-to-search {
    width: 250px;
}

.search-mapping-delete {
    margin-left: auto;
}


.navigation-buttons {
    display: flex;
}

.import-mapping {
}

.import-export-mapping-wrapper {
    padding: 20px 20px 20px 20px;
}

.import-example-csv {
    margin-bottom: 10px;
}

.upload-status {
    font-weight: 600;
}

.upload-failed-msg {
    margin-bottom: 5px;
}

.upload-csv-msg {
    margin-top: 10px;
    margin-bottom: 10px;
}

.csv-processing {
    display: flex;
}

.csv-processing-text {
    margin-right: 7px;
}

.source-item-wrapper {
    display: flex;
    width: 100%;
}

.item-title-wrapper {
    display: flex;
    width: 100%;
}

.collection-item-wrapper {
    display: flex;
    align-self: center;
    padding-left: 6px;
    padding-bottom: 3px;
    align-items: center;
    width: 100%;
}

.collection-item-name {padding-right: 0px;}

.item-title-wrapper {
    display: flex;
    align-items: center;
    padding-bottom: 1px;
}

.collection-item-products {
    padding-left: 5px;
    padding-right: 5px;

}

.arrows-wrapper {
    min-width: 45px;
    display: flex;
    justify-content: center;
}

.dest-item-wrapper {
    display:flex;
    width: 100%;
}
.sticky-item-wrapper {
    display: flex;
    width: 100%;
}

.dest-float-right {
    margin-left: auto;
    display: flex;
}

.select-variants-button {
    margin-right: 10px;
    margin-left: auto;
    align-self: center;
}

.limited-title-wrapper { display: flex; }

.mapping-content-wrapper {
    display: grid;
    padding-left: 8px;
}

.search-mapping-spinner {
    right: 9px;
    bottom: 3px;
    z-index: 300000;
    position: absolute;
}

.search-mapping-input-wrapper {
    display: block;
    position: relative;
}

.dest-products-title {
    display: flex;
}

.randomize-dest {
    display: flex;
    margin-left:auto;
}

.mapping-item-buttons {
    margin-left: auto;
    margin-left: auto;
    display: flex;
    align-items: center;
}

.mapping-item-buttons .button-wrapper.switch-preview {
    display: flex;
    min-width: 110px;
    justify-content: center;
}

.mapping-item-buttons .button-wrapper.switch-preview > button {
  min-width: 101px;
}

.remove-item.sd-mapping-item {
    margin-left:2px;
    cursor: pointer;
}

.view-item.sd-mapping-item {
    cursor: pointer;
    margin-right:5px;
}

.button-wrapper.map-dup button {
     border: none;
     outline: none !important;
     background-color: transparent;
     padding: 0;
 }

.button-wrapper.map-dup button:focus {outline:none !important}


.button-wrapper.map-dup {
    text-align: center;
    margin-left: 8px;
}

.button-wrapper.enable-disable {
    margin-right: 5px;
}

.button-wrapper.enable-disable > button {
    min-width: 86px;
}

.button-wrapper.edit-map > button {
    min-width: 86px;
}





.button-wrapper.sd-switch button {
    border: none;
    outline: none !important;
    background-color: transparent;
    padding: 0;
}

.button-wrapper.sd-switch button:focus {outline:none !important}


.button-wrapper.sd-switch {
    text-align: center;
    margin-right: 8px;
}

.field-tip.dest-tip {
    margin-top: 0;
    padding-left:4px;
}

.navigation-button-delete-save-wrapper {
    margin-left: auto;
}

.import-mapping-wrapper, .export-mapping-wrapper {
    padding: 10px 20px 20px 19px;
    border: 1px solid gainsboro;
    margin-top: 10px;
}

.import-title, .export-title {
    font-size: 21px;
    font-weight: 600;
    margin-bottom: 10px;
}

.export-btn {
    margin-top: 20px;
}

.export-message {
    margin-top: 15px;
}

.mapping-edit-action > .Polaris-Icon {
    max-width: 12px;
    padding-top: 7px;
    margin-left: 3px;
    cursor: pointer;
}

.mapping-on-edit-wrapper {
    display: flex;
    align-items: center;
}

.mapping-on-edit-wrapper .Polaris-TextField__Input {
    height: 24px;
    min-height: inherit;
    font-size: 12px;
}

.mapping-on-edit-wrapper .Polaris-Icon { cursor: pointer;}

.item-index {
    margin-right: 6px;
}

.mapping-title-locale-wrapper {
    display: flex;
}


.mapping-title-wrapper {
    width: 100%;
}

.mapping-locale-wrapper {
    min-width: 170px;
    margin-left: 5px;
}

@media (max-width: 720px) {

.mapping-title-locale-wrapper {
   flex-direction: column;
}

.mapping-locale-wrapper {
    margin-left: 0px;
}

}
