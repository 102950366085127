.warranty-mgmt-items {
    display: flex;
    align-items: center;
    border: 1px solid #C4CDD5;
    border-bottom: solid transparent;
    padding: 10px;
}

.warranty-mgmt-items:last-child {
    border-bottom: 1px solid #C4CDD5;

}

.warranty-mgmt-container {
    margin: 0 auto;
    padding: 5px 20px 20px 20px;
}

.warranty-mgmt-item-buttons {
    display: flex;
    margin-left: auto;
}

.warranty-mgmt-item-time {
    width: 175px;
}

.warranty-mgmt-item-name {
    width: 275px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.warranty-mgmt-item-price {
    width: 100px;

}

.warranty-mgmt-item-time,
.warranty-mgmt-item-name,
.warranty-mgmt-item-price,
.warranty-mgmt-item-discount {
    margin-right: 20px;
}

.warranty-mgmt-item-buttons button {
    margin-left: 10px;
}

.warranty-mgmt-item-pending-actions {
    display: flex;
}

@media (max-width: 720px) {

    .warranty-mgmt-item-buttons button {
        font-size: 10px;
        width: 100%;
    }

    .warranty-mgmt-item-buttons {
        display: block;
    }

    .warranty-mgmt-item-pending-actions {
        display: block;
    }

    .warranty-mgmt-item-time,
    .warranty-mgmt-item-name,
    .warranty-mgmt-item-price {
        margin-right: 10px;
    }

    .warranty-mgmt-item-buttons {
        width: 50%;
    }

    .warranty-mgmt-item-name {
        white-space: inherit;
        overflow: inherit;
        text-overflow: inherit;
        width: 25%;
    }

    .warranty-mgmt-item-time {
        width: 15%;
    }


    .warranty-mgmt-item-price {
        width: 10%;

    }
}


.warranty-mgmt-item-pending-actions button:first-child {
    background: #098056;
}

.warranty-mgmt-item-pending-actions button:first-child .Polaris-Button__Text {
    color: white;

}

.warranty-mgmt-top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.warranty-mgmt-select-wrapper {
    width: 25%
}

@media (max-width: 720px) {
    .warranty-mgmt-select-wrapper {
        width: 33%
    }

}

.warranty-mgmt-button-wrapper {
    display: block;
    margin-top: 10px;

}

.warranty-mgmt-select-order-button {
    margin-top: 5px;
}

.warranty-mgmt-select-order-discount {
    margin-top: 15px;
    margin-bottom: 15px;
    text-align: center;
    border-top: 1px solid lightgrey;
    border-bottom: 1px solid lightgrey;
    color: #404040;
}