#integrations-container {
  padding: 5px 30px 30px 30px;

  .integrations-title {
    font-size: 30px;
    font-weight: bold;
    color: #292929;

  }

  .integrations-subtitle {
    color: #757575;
  }

  .integrations-tabs {
    margin-top: 10px;
  }

  .integration-grid-item-top {
    display: flex;
  }

  .integration-grid-item-badge {
    border-radius: 10px;
    padding: 2px 6px 2px 6px;
    font-size: 11px;
    width: fit-content;

    &.page-builder-app {
      color: #e2656d;
      background-color: #f9e0e2;
    }

    &.customer-loyalty-app {
      color: #2c655d;
      background-color: #f1f6ff;
    }

    &.customer-support-app {
      color: #2c655d;
      background-color: #f1fff4;
    }

    &.translation-app {
      color: #4c6bc9;
      background-color: #e0e6f9;
    }

    &.videos-app {
      background-color: #eeeeee;
    }

    &.dropshipping-app {
      background-color: #f8cf55;
      color: #096dd9;
   }
   &.billing-app {
    background-color: #ffe9fb;
    color: #524747;
   }
   &.inventory-app {
    background-color: #d4e8fc;
    color: black;
   }
   &.seo-app {
    background: #e9e9e9;
    color: #0c4412;
   }

   &.shopify-theme {
    background-color: #0dc03a61;
    color: #0a3effb3;
    }

    &.checkout-app {
      background-color: #ebf3ff;
      color: #2b2626;
    }
  }
  .integration-grid-item-title {
    color: black;
    font-weight: 500;
    margin-left: 1px;
    margin-top: 5px;
  }

  .integration-grid-item-badge-title {}

  .integration-grid-item-description {
    color: #767783;
    padding: 10px 0 2px 0;
    font-size: 12px;
  }

  .integration-grid-item-view {

    display: inline-block;
    border-radius: 6px;
    font-size: 12px;
    color: #868383;
    font-weight: 500;
    margin-top: 0px;
    cursor: pointer;
    padding: 1px 10px;
    margin-top: 8px;
    border: 1px solid #d8d8d8;
  }

  .integration-grid-item-view:hover {
    color: #666161;
    border: 1px solid #b5b5b5;
    background-color: white;


  }

  .integration-grid-item {
    padding: 15px;
    border: 1px solid #d0d0d0;
    border-radius: 4px;
    cursor: pointer;
    .integration-grid-item-image {
      border: none;
      border-radius: 5px;

      &.seo-app {
        border: 0.7px solid rgb(221, 221, 221);
      }
      &.videos-app {
        border: 0.7px solid rgb(221, 221, 221);
      }
    }
  }
  .integration-grid-item:hover {
    background-color: rgb(252, 252, 252);
  }
  .integrations-grid {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(auto-fill, 280px);
    margin: 1.5rem 0 1rem;

    .integration-grid-item-image {
      img {
        width: 48px;
        height: 48px;
        border-radius: 4px;
      }
    }
  }

}
