.warranty-info-wrapper {
    margin: 20px 100px 70px 100px;
    padding: 25px 30px 30px 30px;
    border: 1px solid #e1e1e1;
    background-color: white;
}

.warranty-info-title {
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 20px;
}

.warranty-info-img {
    width: 100%;
    padding-top: 5px;
    padding-bottom: 10px;
}

.warranty-info-img.small {
    width: 50%;
    padding-top: 10px;
}

.warranty-info-wrapper .Polaris-List__Item {
    font-size: 15px;
}


@media (max-width: 720px) {
    .warranty-info-wrapper {
        margin: 20px 30px 50px 20px;
        padding: 10px;

    }

    .warranty-info-img.small {
        width: 100%;

    }

    .warranty-info-title {
        font-size: 25px;
        margin-top: 10px;
    }
}