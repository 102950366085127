.partner-dashboard {
    padding: 30px;
    background-color: #f4f6f8;
    height: 100%;
}

.partner-container {
    max-width: 500px;
    margin: 0 auto;
    padding: 25px;
    border-radius: 3px;
    overflow: hidden;
    background-color: white;
    box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15);
}

.partner-buttons {
    margin-top: 10px;
}

.partner-buttons button {
    margin-right: 10px;
}

@media (max-width: 720px) {
    .partner-buttons button {
        margin-top: 20px;
    }
}

.partner-welcome-image {
    width: 100px;
}

.partner-welcome {
    text-align: center;
    margin-bottom: 20px;

}

.partner-welcome-text {
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 1.4rem;
    margin: 0;
    padding-top: 10px;
}

.partner-dashboard-header-image {
    width: 33px;
}

.partner-dashboard-header {
    display: flex;
    align-items: center;
    padding: 25px;
    box-shadow: 0px 1px #dfe3e8;

}

.partner-dashboard-header-text {
    margin-left: 10px;
    font-weight: 600;
    font-size: 2.2em;
}

.partner-dashboard-body {
    background-color: #f4f6f8;
}

.partner-dashboard-content {
    padding: 25px;
}

.partner-dashboard-header-buttons {
    margin-left: auto;
}

.partner-dashboard-header-buttons button {
    margin-right: 5px;
}

.partner-dashboard-banner .Polaris-Banner__Ribbon {
    display: none;

}

.partner-dashboard-banner .Polaris-Banner--withinPage.Polaris-Banner--statusSuccess {
    background: #FDFDFD;
    box-shadow: inset 0 3px 0 0 #62b1ed, inset 0 0 0 0 transparent, 0 0 0 1px rgb(63 63 68 / 5%), 0 1px 3px 0 rgb(63 63 68 / 15%);
    border-radius: 0;
}

.partner-dashboard-banner {
    margin-bottom: 10px;
}

.partner-dashboard-cards .Polaris-Card {
    margin-top: 10px;
    border-radius: 0;
    overflow: hidden;
    background-color: white;
    box-shadow: 0 0 0 1px rgb(63 63 68 / 5%), 0 1px 3px 0 rgb(63 63 68 / 15%);
}

@media (min-width: 720px) {

    .partner-dashboard-general-cards {
        display: flex;
    }

    .partner-dashboard-general-cards .Polaris-Card {
        width: 25%;
        margin-right: 20px;
        height: 100px;
    }

    .partner-dashboard-general-cards .Polaris-Card:last-child {
        margin-right: 0px;

    }
}

.partner-dashboard-give-get {
    display: flex;
    margin-top: 15px;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;

}

.partner-dashboard-give {
    margin-left: auto;
    font-size: 16px;
    font-weight: 500;
}

@media (min-width: 720px) {

    .partner-dashboard-payment-cards {
        display: flex;
    }

    .partner-dashboard-payment-cards>.Polaris-Card:first-child {
        width: 60%;
        margin-right: 10px;
    }

    .partner-dashboard-payment-cards>.Polaris-Card:not(first-child) {
        width: 30%;
    }

    .partner-dashboard-payment-cards>.Polaris-Card:last-child {
        margin-left: 10px;
    }

    .partner-dashboard-payment-cards>.Polaris-Card:nth-child(2) .Polaris-Card__Section {
        min-height: 206px;
    }

    .partner-dashboard-payment-cards>.Polaris-Card:nth-child(3) .Polaris-Card__Section {
        min-height: 206px;
    }

    .partner-dashboard-payment-cards .Polaris-Card__Section {
        height: 100%;
    }


    .partner-dashboard-affiliate-link-cards .Polaris-Card:last-child {
        margin-top: 0px;
    }
}

.partner-dash-url-copy-wrapper {
    display: flex;
}

.partner-dash-ref-url-wrapper {
    width: 100%;
}

.partner-dash-paypal-btn-wrapper {
    margin-top: 5px;
}

.partner-dash-spinner-wrapper {
    height: inherit;
    min-height: 142px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 40px;
}

.partner-dash-tbl {
    padding: 13px;
    width: 100%;
    margin-top: 10px;
    border-radius: 0;
    overflow: hidden;
    background-color: white;
    box-shadow: 0 0 0 1px rgb(63 63 68 / 5%), 0 1px 3px 0 rgb(63 63 68 / 15%);
    display: block;
    overflow-x: auto;
    white-space: nowrap;
}

.partner-dash-tbl th {
    text-align: left;
    border-bottom: .1rem solid #d3dbe2;
    padding-bottom: 5px;

}

.partner-dash-tbl.partner-dash-ref-stores th {
    width: 12.5%;
}

.partner-dash-tbl.partner-dash-ref-stores th:last-child {
    width: 1%;
}

.partner-dash-tbl.partner-dash-payment th {
    width: 50%;
}

.partner-dash-tbl.partner-dash-payment th:last-child {
    width: 1%;
}

.partner-dash-tbl td {
    padding-top: 13px;
    font-size: 20px;
    font-weight: 300;
    text-align: center;
}

.partner-dash-tbl td:first-child {
    text-align: left;
    width: 6%;
}

.partner-dash-tbl th {
    text-align: center;
}

.partner-dash-tbl th:first-child {
    text-align: left;
    width: 6%;

}

@media (min-width: 720px) {

    .partner-dash-tbl.partner-dash-payment td:nth-child(2) {
        padding-right: 100px;
    }

    .partner-dash-tbl.partner-dash-payment th:nth-child(2) {
        padding-right: 100px;
    }
}

.partner-dash-no-data {
    text-align: center !important;
}

.partner-dash-verify-msg {
    margin-top: 5px;
    color: green;
}

.check-brand-avail-spinner {
    position: absolute;
    top: 7px;
    z-index: 100;
    right: 11px;
}

.check-brand-avail-check {
    position: absolute;
    top: 5px;
    z-index: 100;
    right: 6px;
}

.check-brand-avail-check .green .Polaris-Icon--colorBase svg {
    fill: green;
}

.check-brand-avail-check .red .Polaris-Icon--colorBase svg {
    fill: red;
}

.check-brand-avail-wrapper {
    position: absolute;
    top: 7px;
    z-index: 100;
    right: 11px;

}

.check-brand-avail-text {
    position: absolute;
    top: 6px;
    z-index: 100;
    right: 34px;
    font-weight: 400;
}

.partner-brand-wrapper {
    position: relative
}

.existing-partners {
    color: rgb(154, 154, 154);
    margin-top: 2px;
    margin-bottom: 10px;
    display: block;
    margin-left: 5px;

}

.partner-login-wrapper {
    display: flex;
}