.mix-and-match-products-wrapper {

}



.mm-save-btn { margin-right: 5px;}

.mm-locale-wrapper {
  padding: 0px 9px 12px 6px;
  margin: 9px 0px;
  border: 1px solid #dadada;
  border-radius: 5px;
  background-color: #f6f6f6;
}

.mm-discount-wrapper {
  background-color: #f6f6f6;
  padding: 1px 9px 12px 6px;
  border: 1px solid #dadada;
  border-radius: 5px;
}

.mm-collection-wrapper {
  margin-top: 10px;
  background-color: #f6f6f6;
  padding: 1px 9px 12px 6px;
  border: 1px solid #dadada;
  border-radius: 5px;

  .mm-add-collection-wrapper {
    display: flex;
    margin-bottom: 10px;
    margin-top: 10px;

    .mm-add-collection {
      display: block;
      margin-right: 5px;
    }

  }
}

.mm-discount-wrapper {
  .mm-tiered-discount-wrapper {
    .mm-discount-tier-wrapper {
      padding: 5px 10px 10px 10px;
      border: 1px solid #c8c8c8;
      margin-bottom: 6px;
      border-radius: 4px;
      position: relative;
      background-color: #eaeaea;
    }
    .mm-delete-tiered-discount {
      position: absolute;
      top: 4px;
      right: 10px;
      cursor: pointer;
    }

    .mm-add-tier-button-wrapper {
      display: block;
      margin: 12px 0px;
    }

  }
}

.mm-more-settings {
  margin-top: 10px;
  background-color: #f6f6f6;
  padding: 1px 9px 12px 6px;
  border: 1px solid #dadada;
  border-radius: 5px;
}

.mm-offer-location {
  margin-top: 10px;
  background-color: #f6f6f6;
  padding: 1px 9px 12px 6px;
  border: 1px solid #dadada;
  border-radius: 5px;
}

.mm-offer-name {
  margin-top: 15px;
  margin-bottom: 4px;
}

span.mm-offer-name-tip {
  color: gray;
}

.mm-search-item {
  padding: 10px 7px;
  border-radius: 8px;
  margin-top: 1px;
  display: flex;
  place-items: center;
  position: relative;
  border: 1px solid #d6d6d6;
  cursor: pointer;
}

.mm-search-item:hover {
  background-color: #f1f1f1;
}

.mm-collection-title {

}

.mm-collection-count {
  margin-left: 3px;
}

.mm-selected-collection {
  padding: 10px;
  border: 1px solid #b9b9b9;
  margin-top: 5px;
  background-color: #eaeaea;
  position: relative;

  .mm-func-selected-products {
    padding: 5px;
    /*border: 1px solid #bababa;*/
    margin-bottom: 8px;
    overflow: scroll;
    align-items: center;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: 39px;
    /*min-height: 70px;*/
    width: fit-content;

    .mm-func-selected-product {
      display: flex;
      width: auto;
      max-width: 200px;
      border: 1px solid #ccc5c5;
      padding: 5px;
      border-radius: 7px;
      margin-right: 3px;
      .mm-func-selected-product-img {
        margin-right: 5px;
      }

      .mm-func-selected-product-title {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
   }
  }

}

.mm-selected-collection-qty {
  display: flex;
  flex-direction: column;
}

.mm-selected-collection-qty .remove-item {
  margin-right: 10px;
  margin-left: auto;
  position: absolute;
  top: 10px;
  right: 4px;
  cursor: pointer;
}

.mm-selected-collection-qty .edit-item {
  margin-right: 10px;
  margin-left: auto;
  position: absolute;
  top: 43px;
  right: 4px;
  cursor: pointer;
}

.mm-selected-collection-qty > div {
  width: 100%;
  margin-right: 13px;

}

.mm-selected-collection-name {
  font-weight: 500;
  text-decoration: underline;
  margin-bottom: 4px;
  display: block;

}

.mm-selected-collections-wrapper {
  border: 1px solid #dbdbdb;
  padding: 10px;
  margin-top: 5px;
}

.mm-config-title {
  margin-top: 7px;
  margin-bottom: 1px;
}

.mm-offer-locations-wrapper {
  display: grid;
  padding: 10px;
  border: 1px solid #dbdbdb;
  background-color: white;
}

.mm-selected-collections-wrapper-empty {
  /*margin-bottom: 18px;*/
}

.mm-create-offer-btn-wrapper {
  margin-bottom: 10px;
  display: block;
  .mm-create-offer-btn {
    margin-right: 5px;;
  }
}

.mnm-offer-type-title {
  font-weight: 600;
  margin-top: 15px;
  margin-bottom: 3px;
  display: block;
}

.mix-and-matches-list-item {
  border: 1px solid #dbdbdb;
  display: flex;
  align-items: center;
  padding: 9px;
  margin-top: -1px;


  .mix-and-matches-list-item-arrow-wrapper {
    display: flex;
    flex-direction: column;
    margin-right: 9px;
    cursor: pointer;
  }

}

.mm-list-action-wrapper {
  margin-left: auto;
  display: flex;
  align-items: center;

  .mm-remove-list-item:{
    margin-left: 9px;
    cursor: pointer;
  }
}

.mm-item-switch-preview-btn {
    min-width: 101px;
    display: flex;
    justify-content: center;

    button {
      min-width: 100px;
    }
}

.mm-promo-page-link, .mm-item-short-code, .mm-item-switch-preview-btn {
  margin-right: 12px;
}

.mm-item-enable-disable-btn {
  margin-right: 4px;
      margin-right: 4px;
    min-width: 85px;
    display: flex;
    justify-content: center;
}

.mm-item-edit-btn {
  margin-right: 8px;
}

.mm-item-edit-btn button {
  width: 80px;
}


.mm-short-code-wrapper {
  height: 110px;
  display: flex;
  align-items: center;
  padding: 15px;
}

.mm-short-code-wrapper > code {
  text-align: center;
}

.copy-short-code {
  cursor: pointer;
  margin-right: 10px;
  top: 8px;
  left: 8px;
  position: absolute;
  display: none;
}

.view-mnm-item {
  cursor: pointer;
}

.mm-add-trigger-collection {
  margin-top: 3px;
  margin-bottom: 3px;

}

.mm-not-supported-wrapper {
  padding: 10px 10px;
}

.mm-not-supported-title {
  font-size: 15px;
}

.mm-not-supported-btn-wrapper {
  margin-top: 10px;
}

.mm-add-trigger-collection-item {
  padding: 10px;
  border: 1px solid #bbbbbb;
  display: flex;
  align-items: center;
  margin-top: -1px;
  background-color: #ececec;
}


span.mm-trigger-collection-remove {
  margin-left: auto;
}

.mm-add-trigger-collection-text { font-weight: 600; }

.mm-color-field-title {
  margin-top: 15px;
}

.mnm-based-on-info {
    color: gray;
    margin-left: 10px;
}

.mnm-func-selected-product-list {

  overflow: scroll;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: grid;
  grid-template-columns: repeat(3,1fr);
  grid-auto-rows: 73px;

  .mnm-func-selected-product {
    padding: 5px;
    margin-bottom: -1px;
    border: 1px solid #a6a6a6;
    border-radius: 5px;
    display: flex;
    background-color: #efefef;
    flex-direction: column;
    margin-right: 2px;

    .mnm-func-selected-product-qty {
      margin-top: 2px;
      padding-top: 2px;
      display: block;
      border-top: 1px solid #d1d1d1;

      input {
        max-width: 39px;
        margin-left: 4px;
        text-align: center;
        min-height: inherit;
        height: 20px;
      }
      > div:first-child {

        display: flex;
        align-items: center;
      }

      .Polaris-Labelled__LabelWrapper {
        margin: 0;
      }
    }

    .mnm-func-selected-product-wrapper {
      display: flex;
      align-items: center;
      height: 26px;
      margin-bottom: 2px;

    }

    .mnm-func-selected-product-image {
      width: 25px;
      height: 25px;
      border-radius: 6px;
    }

    .mnm-func-selected-product-title {
      /*display: none;*/
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1; /* number of lines to show */
              line-clamp: 1;
      -webkit-box-orient: vertical;
      width: auto;
      margin-left: 5px;
      padding-right: 15px;
    }

    .mnm-func-select-product-img-placeholder {
      display: block;
      height: 35px;
      width: 35px;
    }

    .mnm-func-remove-product {
      margin-right: 5px;
      margin-left: auto;
    }
 }
}

.mnm-func-search-product-wrapper {

  .mnm-func-select-product-list {
    max-height: 370px;
    overflow-y: scroll;
    padding-bottom: 2px;
  }

  .mnm-func-search-text-field-wrapper {
    margin-bottom: 5px;
  }

  .mnm-func-select-product-wrapper {
    .mnm-func-select-product {
      display: flex;
      align-items: center;
      border: 1px solid #e3e3e3;
      margin-bottom: -1px;
      border-radius: 5px;
      padding: 5px;
      cursor: pointer;

      .mnm-func-select-product-img {
        height: 35px;
        width: 35px;
        margin-right: 8px;
        margin-left: 6px;
      }

      .mnm-func-select-product-img-placeholder {
        display: block;
        height: 35px;
        width: 35px;
      }
    }
    .mnm-func-select-product-title {
      //margin-left: 10px;
    }
  }
}

.ant-modal.mnm-func-offer-product-group-modal {

  .ant-modal-content {
    height: inherit;
    min-height: 500px;
  }
}

.mnm-select-product-modal {
  .mnm-func-selected-product-list {
    grid-auto-rows: 39px;
  }
}

.mm-select-parent-product-wrapper {
  overflow: scroll;
  max-height: 318px;
    .mm-parent-product-search-item {
      padding: 10px 8px;
      border: 1px solid #e4e4e4;
      margin-top: -1px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
      display: flex;
      align-items: center;
    }
    img.mm-parent-product-search-item-img {
      margin-right: 10px;
      width: 25px;
      height: 25px;
      border-radius: 5px;
  }
}

.mm-parent-product-field-wrapper {
  display: flex;
  flex-direction: column;
}

.mm-parent-product-field-product-title {
  margin-top: 2px;
}

.mm-parent-product-field-product-wrapper {
  padding: 10px;
  border: 1px solid #cccccc;
  display: flex;
  align-items: center;
}

.mm-parent-product-field-product-img {
  margin-right: 8px;
  border-radius: 5px;
}

.mm-parent-product-select-product-button {
  margin-top: 10px;
  margin-bottom: 2px;
}