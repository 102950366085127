.products-cart-drawer {
    .cart-drawer-card-wrapper {
        min-height: 300px;
        display: flex;
        align-items: center;

        .cart-drawer-offer-wrapper { margin-top: 7px;}
    }

    .cart-drawer-card-wrapper.cart-drawer-loaded {
        display: block;
        margin-bottom: 50px;
    }

    .spinner-wrapper {
        display: table;
        margin: 0 auto;
    }


    .cart-drawer-offer-inner {
        display: flex;
        border: 1px solid #dfdfdf;
        padding: 15px;
        align-items: center;

        img {
            width: 50px;
        }
    }

    .cart-drawer-offer-inner:nth-child(2) {
        margin-top: -1px;
    }

    .cart-drawer-offer-inner > button {
        margin-left:auto;
    }

    .cart-drawer-offer-inner {
        .zrx-cart-drawer-carets {
            width: 40px;
        }
    }

    .cart-drawer-offer-title {
        margin-left: 10px;
    }
}

.search-products {

    &.cart-drawer-search {
        width: 100%;
    }

    .cart-drawer-searchable-item {
        align-self: center;
        margin-left: 10px;
    }
}
