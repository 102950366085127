.script-title {
  padding-bottom: 10px;
}

.snippet-code {
  padding: 15px 15px 5px 0;
  font-size: 10px;
  max-width: 900px;
}

.did-it-button {
  padding-top: 20px;
}

.Polaris-TextContainer {
  padding-top: 5px !important;
  padding-left: 5px !important;

}

.roi-spinner {
  display: flex;
  align-items: center;

}

.no-numbers {
  color: rgb(162, 162, 162);
  padding-top: 40px;
}

.orders-status {
  font-weight: 500;
}

.roi-product-title {
  display: flex;
}

.roi-product-title-span {
  align-self: center;
  padding-left: 10px;
}

.roi-summary {
  padding-top: 15px;
  padding-bottom: 15px;
}

.total-orders {}

.total-zoorix-orders-item {
  padding-top: 2px;
}

.num-orders {
  font-weight: bold;
}

.roi-card {
  display: inline-block;
  min-width: 23%;
  border: 1px solid black;
  margin: 1%;
  padding: 5px 10px;
}

.roi-card.width-50 {
  min-width: 48%;
  margin-bottom: 50px;

}

.roi-summary-cards {
  display: flex;
  justify-content: center;
}

.roi-graph-cards {
  display: flex;
  justify-content: center;
  max-height: 300px;
}

@media (max-width: 720px) {

  .roi-summary-cards,
  .roi-graph-cards {
    display: grid;
    justify-content: inherit;
  }
}


.roi-title-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.roi-tooltip {
  margin-left: AUTO;
  margin-right: 5px;
}

.roi-tooltip span {
  outline: none;
}

.roi-card-title {
  font-weight: 600;
  text-decoration: underline;
}

.roi-number {
  float: right;
  font-weight: 600;
}

.roi-summary .Polaris-Card {
  padding: 0px 0px 40px 20px;
}

.roi-summary-products {
  margin-bottom: 80px;
}

.analytics-minitab-nav {
  cursor: pointer;
  margin-bottom: 10px;
  font-weight: 400;
}

.analytics-minitab-nav.selected {
  font-weight: 600;
  border: 1px solid #d5d5d5;
  border-radius: 7px;
  padding-left: 4px;
  margin-left: -6px;
  padding-top: 0px;
  padding-bottom: 0px;
  background-color: rgb(246, 246, 246);
}

.roi-dashboard-wrapper {
  display: flex;
}

.roi-dashboard-date-picker {
  padding: 14px;
}

.roi-dashboard-content {
  width: 100%;
  min-height: 400px;
  padding: 5px 20px 20px 20px;
}

.roi-data-table thead>tr>th {
  font-weight: 600;
}

.roi-spinner-wrapper {
  width: 100%;
  min-height: 400px;
  display: flex;
  justify-content: center;
}

.roi-clickable-offer {
  cursor: pointer;
  text-decoration: underline;
}

@media (max-width: 1200px) and (min-width: 720px) {

  .roi-number {
    display: block;
    margin-bottom: 10px;
    float: inherit;

  }

}

.roi-dashboard-date-picker-email-wrapper {
  display: flex;
  align-items: center;
}

span.roi-dashboard-email-data {
  margin-left: auto;
}

.email-roi-data-wrapper {
  margin-left: auto;
}

.failed-to-fetch-roi-ca-text {
  margin-right: 10px;
  font-weight: 500;
}

.roi-too-long-email {
  margin-left: 11px;
}

.roi-dashboard-date-range {
  border: 1px solid black;
  padding: 15px;
  margin-left: 10px;
}

.roi-dashboard-date-range-title {
  margin-right: 9px;
  font-weight: 600;
}

.roi-dashboard-date-range-values {
  font-weight: 5  00;
}