.support-image-wrapper {
    max-width: 300px;
}

.support-sending-request {
    display: flex;
    align-items: center;

    .support-sending-request-text {
        margin-left: 20px;
    }
}