.faq-snippet-code {
  padding: 15px 0px 5px 0;
  font-size: 10px;
}

.Polaris-Link {
  color: #006e52;
}


.faq-cart-popup-code {
  margin-top: 10px;
}

.faq-cart-popup-code .Polaris-TextStyle--variationCode {
  padding: 10px;
}

.faq-copy-snippet-code {
  padding: 15px 0px 5px 0;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  font-size: 1.15em;
}

.faq-copy-snippet-code>div {
  background-color: var(--p-surface-subdued);
  box-shadow: inset 0 0 0 1px var(--p-border-subdued);
  padding: 10px;
  width: fit-content;
  margin-bottom: 10px;

}

.faq-help-iframe {
  width: 100%;
  height: 100%;
}

#faq-panel .Polaris-Card__Section {
  padding: 0;
  height: 100%;
}

.help-iframe-wrapper {
  height: 100%;
}

.section-title-wrapper {
  padding: 10px;

}

.section-title-wrapper>.section-title {
  font-size: 30px;
  font-weight: bold;
  color: #292929;

}

.section-title-wrapper>.section-subtitle {
  color: #757575;
}


.zrx-support-page .Polaris-Heading {
  font-size: 30px;
  font-weight: 700;
}

.zrx-support-page .Polaris-Layout__Annotation {
  margin-top: 0;
  padding-top: 10px;
}