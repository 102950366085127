#upgrade-container {

    @media (min-width: 640px) {
        .bundle-upgrade {
            padding: 20px 330px;
        }
    }

    .bundle-upgrade article {
        width: 100%;
        max-width: 1000px;
        margin: 0 auto;
        height: 1000px;
        position: relative;
    }

    .bundle-upgrade ul {
        display: flex;
        top: 0px;
        z-index: 10;
        padding-bottom: 14px;
    }

    .bundle-upgrade li {
        list-style: none;
        flex: 1;
    }

    .bundle-upgrade li:last-child {
        border-right: 1px solid #DDD;
    }

    .bundle-upgrade>button:first-child {
        width: 100%;
        border: 1px solid #DDD;
        border-right: 0;
        border-top: 0;
        padding: 10px;
        background: #FFF;
        font-size: 14px;
        font-weight: bold;
        height: 60px;
        color: #999
    }

    .bundle-upgrade li.active button {
        background: #F5F5F5;
        color: #000;
    }

    .bundle-upgrade table {
        border-collapse: collapse;
        table-layout: fixed;
        width: 100%;
    }

    .bundle-upgrade th {
        background: #F5F5F5;
        width: fit-content;
    }

    /*.bundle-upgrade td, th {
        height:53px
    }*/
    .bundle-upgrade td,
    th {
        border: 1px solid #DDD;
        padding: 10px;
        empty-cells: show;
    }

    .bundle-upgrade td,
    th {
        text-align: left;
    }

    .bundle-upgrade td+td,
    th+th {
        text-align: center;
    }

    .bundle-upgrade td.default {
        display: table-cell;
    }

    .bundle-upgrade .bg-purple {
        border-top: 3px solid #A32362;
    }

    .bundle-upgrade .bg-red {
        border-top: 3px solid #fc4903;
    }

    .bundle-upgrade .bg-blue {
        border-top: 3px solid #0097CF;
    }

    .bundle-upgrade .bg-green {
        border-top: 3px solid green;
    }

    .bundle-upgrade .sep {
        background: #F5F5F5;
        font-weight: bold;
    }

    .bundle-upgrade h1 {
        font-size: 48px;
        text-align: center;
        margin: 30px 0;
    }

    .bundle-upgrade .txt-l {
        font-size: 28px;
        font-weight: bold;
    }

    .bundle-upgrade .txt-2 {
        font-size: 18px;
        margin: 10px 0;
    }

    .bundle-upgrade .txt-top {
        position: relative;
        top: -9px;
        left: -2px;
    }

    .bundle-upgrade .tick {
        font-size: 18px;
        color: #2CA01C;
    }

    .bundle-upgrade .cross-mark {
        font-size: 18px;
        color: #a01c1c;
        font-weight: bold;
    }

    .bundle-upgrade .hide {
        border: 0;
        background: none;
    }

    @media (min-width: 640px) {
        .bundle-upgrade ul {
            display: none;
        }

        .bundle-upgrade td,
        th {
            display: table-cell !important;
        }

        .bundle-upgrade td,
        th {
            width: 330px;
        }

        .bundle-upgrade td+td,
        th+th {
            width: auto;
        }
    }

    .upgrade-top-bar {
        display: inline-block;
        width: 100%;
        height: 50px;
        background-color: rgb(0, 6, 57);
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        line-height: 50px;
        color: white;
    }

    .upgrade-app-head {
        display: flex;
        flex-direction: row;
        -webkit-box-pack: center;
        justify-content: center;
        width: 100%;
        background-color: rgb(244, 246, 248);
        padding: 40px 35px;
        margin: auto;
    }

    .upgrade-app-icon {
        max-width: 84px;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 6px;
        overflow: hidden;
    }

    .upgrade-app-title {
        font-weight: 500;
        font-size: 28px;
        line-height: 33px;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        color: rgb(33, 43, 54);
        margin-bottom: 12px;
    }

    .upgrade-partner-title {
        font-size: 16px;
        line-height: 19px;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        margin-bottom: 15px;
        color: rgb(145, 158, 171);
    }

    .upgrade-details-wrapper {
        display: flex;
        flex-direction: column;
        max-width: 460px;
        margin-left: 21px;
    }

    .upgrade-app-desc {
        font-size: 22px;
        line-height: 26px;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        margin-bottom: 18px;
        color: #42474c;

    }

    .upgrade-app-reviews {
        margin-bottom: 20px;
        font-size: 18px;
        line-height: 18px;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        color: rgb(100, 115, 129);
    }

    .upgrade-app-reviews::before {
        content: url(https://public.zoorix.com/images/star.svg);
        padding-top: 1px;
    }

    .upgrade-install-btn {
        display: flex;
    }

    .upgrade-install-btn button:first-child {
        width: 150px;
        height: 58px;
        background: rgb(55, 126, 98);
        box-shadow: rgba(182, 191, 199, 0.4) 0px 0px 10px;
        border-radius: 3px;
        cursor: pointer;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        -webkit-box-align: center;
        align-items: center;
        text-align: center;
        color: rgb(255, 255, 255);
        margin-bottom: 20px;
        text-decoration: none;
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
    }

    .upgrade-install-btn button:nth-child(2) {
        height: 58px;
        background: rgb(255, 255, 255);
        box-shadow: rgba(182, 191, 199, 0.4) 0px 0px 10px;
        border-radius: 3px;
        cursor: pointer;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        -webkit-box-align: center;
        align-items: center;
        text-align: center;
        color: rgb(55, 126, 98);
        margin-bottom: 20px;
        text-decoration: none;
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        box-shadow: none;
        background-color: transparent;
        color: #008060;
        border-width: 0.125em;
        border-style: solid;
        border-color: #008060;
        padding: 1em 1.75em;
        margin-left: 15px;
    }

    .upgrade-install-btn button:nth-child(2):hover {
        background: rgb(55, 126, 98);
        color: white
    }

    .upgrade-install-btn button:nth-child(1):hover {
        background: rgb(36, 94, 71);
    }

    .upgrade-install-btn .Polaris-Button__Text {
        font-weight: 700;
        font-size: 17px;
    }

    .upgrade-installation-notes {
        display: inline-block;
        color: rgb(100, 115, 129);
        font-size: 16px;
        line-height: 19px;
    }

    .upgrade-key-benefit-images-container {
        display: flex;
        flex-direction: row;
        -webkit-box-pack: center;
        justify-content: center;
        width: 100%;
        background: rgb(255, 255, 255);
        padding: 40px 80px;
        margin: 0 auto;
        text-align-last: center;
    }

    .upgrade-key-benefit-image {
        border-radius: 6px;
        margin-bottom: 27px;
        box-shadow: 0 0 0.3em 1px #6b7177;
        height: auto;
        max-width: 260px;
    }

    .upgrade-video-container {
        width: 350px;
        height: 205px;
        border-radius: 5px;
        margin: auto 0px auto 75px;
        overflow: hidden;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    }

    .upgrade-key-benefit-title {
        font-weight: 500;
        font-size: 22px;
        line-height: 26px;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        text-align: center;
        color: rgb(33, 43, 54);
        padding: 0px 20px;
        margin-bottom: 20px;
        justify-content: center;
    }

    .upgrade-key-benefit-desc {
        max-width: 200px;
        text-align: center;
        margin: 0 auto;
    }

    .upgrade-key-benefit-image-wrapper:not(:last-child) {
        padding: 0 80px 0px 0;
    }

    @media (max-width: 720px) {
        .upgrade-app-head {
            flex-direction: column;
        }

        .upgrade-app-icon {
            margin: 0 auto;
            margin-bottom: 20px;
        }

        .upgrdade-video-container {
            width: inherit;
            margin: inherit;
            margin-top: 40px;
            margin-bottom: 20px;

        }

        .upgrade-key-benefit-images-container {
            flex-direction: column;
            padding: inherit;
            margin-top: 50px;
        }

        .upgrade-install-btn button {
            width: 100%;
        }

        .upgrade-details-wrapper {
            margin: 0;
            text-align: center;
            align-items: center;
        }

        .upgrade-app-title {
            font-size: 25px;
        }

        .upgrade-key-benefit-image-wrapper {
            padding: 0;
            margin-bottom: 40px;
        }

        .upgrade-key-benefit-image-wrapper:not(:last-child) {
            padding: 0;
        }

        .upgrade-install-btn {
            width: 100%;
        }
    }

    .upgrade-install-btn.upgrade-call-to-action {
        display: flex;
        justify-content: center;
    }

    .upgrade-install-btn.upgrade-call-to-action button {
        margin-bottom: 0;
        height: 35px;
        margin: 0 auto;

    }

    .upgrade-install-btn.upgrade-call-to-action.btn-disabled .Polaris-Button__Text {
        font-weight: 300;
    }

    .upgrade-pricing-table-wrapper {
        background-color: #e8e9eb;
        padding: 50px;
        margin: 25px 70px;

    }

    .upgrade-pricing-table-title-wrapper {
        margin-bottom: 12px;
        display: flex;
    }

    .upgrade-pricing-table-title {
        font-size: 23px;
        font-weight: 700;
    }

    .upgrade-pricing-table-trial {
        margin-left: 15px;
        color: #6b7177;
        display: flex;
        align-items: center;
    }

    .upgrade-pricing-table-full-pricing {
        text-decoration: none;
        cursor: pointer;
        color: #008060;
        display: flex;
        margin-left: auto;
        display: none;
    }

    .upgrade-install-call-to-action {
        text-align: center;
        text-align: center;
        font-size: 22px;
        font-weight: 400;
        margin: 40px 0 25px 0px;
    }

    .upgrade-install-btn-wrapper .upgrade-install-btn {
        display: flex;
        justify-content: center;
    }

    .upgrade-install-call-to-action-free {
        font-weight: 600;
    }

    .bundle-upgrade-compare-title {
        text-align: center;
        font-size: 22px;
        font-weight: 600;
        margin: 40px 0 20px 0px;
    }

    .upgrade-reviews {
        margin-top: 60px;
        margin-bottom: 50px;
    }

    .upgrade-reivews-wrapper {
        position: relative;
        max-width: 1140px;
        margin-right: auto;
        margin-left: auto;
        padding-right: 32px;
        padding-left: 32px;
    }

    .upgrade-grid-review {
        grid-column-gap: 40px;
        -ms-grid-columns: 0.5fr 1fr;
        grid-template-columns: 0.5fr 1fr;
        -ms-grid-rows: auto;
        grid-template-rows: auto;
    }

    .upgrade-w-layout-grid {
        display: -ms-grid;
        display: grid;
        grid-auto-columns: 1fr;
        -ms-grid-columns: 1fr 1fr;
        -ms-grid-rows: auto auto;
        grid-template-rows: auto auto;
        grid-row-gap: 16px;
        grid-column-gap: 16px;
    }

    .review-stars {
        display: flex;
        color: #ffd000;
    }

    .start-review::before {
        content: url(https://public.zoorix.com/images/star.svg);
        padding-top: 1px;
    }

    .overall-rating {
        display: flex;
        font-size: 26px;

    }

    .overall-rating .start-review {
        padding-top: 4px;
    }

    .overall-rating-text {
        font-size: 26px;
        line-height: 30px;
        font-weight: 700;
    }

    .rating-star-text {
        display: flex;
        align-items: center;
    }

    .upgrade-based-reviews-text {
        color: #0a1023;
        font-size: 16px;
        line-height: 1.5em;
        margin-top: 5px;
    }

    .rating-right h2 {
        margin-top: 0;
        margin-bottom: 16px;
        color: #0a1023;
        font-size: 36px;
        line-height: 48px;
        font-weight: 700;
        letter-spacing: -1px;
    }

    .review-block:not(:first-child) {
        margin-top: 20px;
    }

    .review-author {
        font-weight: 600;
        margin-top: 8px;
        padding-left: 4px;
    }

    .review-block>p {
        padding-left: 4px;
    }

    .see-more-reviews {
        color: #008060;
    }

    .see-more-reviews-wrapper {
        margin-top: 20px;
    }

    .upgrade-faq {
        padding: 0px 50px;
        margin-top: 100px;
        margin-bottom: 75px;
    }

    .upgrade-faq-grid {
        display: grid;
        grid-template-rows: auto;
        grid-template-columns: 1fr 1fr;
        padding: 0px 150px;

    }

    .upgrade-faq-block {
        margin-bottom: 50px;
        max-width: 500px;
    }

    .upgrade-faq-question {
        font-weight: 700;
        font-size: 16px;
        margin-bottom: 10px;
    }

    .upgrade-faq-answer {
        font-size: 16px;
        line-height: 28px;
        font-weight: 400;
    }

    .upgrade-faq-title {
        text-align: center;
        font-size: 31px;
        font-weight: 700;
        margin-bottom: 50px;
    }

    .start-review {
        min-width: 24px;
    }

    .upgrade-install-btn-wrapper.grey-wrapper {
        padding: 20px;
        background-color: #e8e9eb;
    }

    .upgrade-install-btn-wrapper.grey-wrapper .upgrade-install-call-to-action {
        margin: 25px 0 25px 0px;
    }

    @media (max-width: 720px) {

        .upgrade-faq-grid {
            padding: 0;
        }

        .upgrade-reivews-wrapper {
            padding-right: 0px;
            padding-left: 0px;
        }

        .upgrade-video-container {
            margin: 20px auto;
        }

        .bundle-upgrade {
            padding: 20px;
        }

        .upgrade-install-btn-wrapper {
            padding: 20px;
        }

        .upgrade-pricing-table-wrapper {
            padding: 25px;
            margin: 25px;
            border-radius: 2px;
            ;
        }

        .upgrade-faq-block {
            padding: 3px;
        }

        .review-block {
            margin-bottom: 35px;
        }

        .upgrade-install-call-to-action {
            line-height: 30px;
        }
    }

    .upgrade-cart {
        margin-left: 10px;
        ;
    }

    .upgrade-pricing-table-wrapper .our-pricing {
        display: none;
    }
}