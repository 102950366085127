.bundle-product-image {
    background-size: contain;
    background-repeat: no-repeat;
    height: 36px;
    width: 36px;
    background-position: left center;
    margin-left: 10px;
}

.bundle-product-image.show-on {
    margin-left: 0;
    margin-right: 10px;
    height: 24px;
    width: 24px;
}

.bundle-item-wrapper,.product-to-show-on .remove-item {
    cursor: pointer;
}

.bundles {
    min-height: 520px;
}

.bundle-product-label {
    margin-bottom: 5px;;
}

.product-container.searchable.bundle{
    display: flex;
    align-items: center;
}

.bundle-searchable-item {
    margin-left: 10px;
}

.bundle-line-item {
    display: flex;
    align-items: center;
}

.bundle-line-item-wrapper {
    display: flex;
    align-items: center;
    border: 1px solid rgb(218, 218, 218);
    min-height: 70px;
    padding-left: 10px;
}

.choose-bundle-product {
    margin-left: auto;
    margin-right: 15px;
}

.bundle-item-title {
    margin-left: 10px;
}

.bundle-item-wrapper {
    display: flex;
    align-items: center;
    min-height: 50px;
    border: 1px solid rgb(218, 218, 218);
    border-bottom: 1px solid transparent;
    padding-left: 20px;
}

.bundle-item-wrapper.bad {
    background-color: lightcoral;
}


.bundle-item-wrapper:last-child {
    border-bottom: 1px solid rgb(218, 218, 218);
}


 .bundle-edit-button {
    margin-right: 10px;
}

.bundle-show-short-code-button {
    display: flex;
    min-width: 91px;
    justify-content: center;
}

.bundle-switch-preview-button {
    min-width: 100px;
    display: flex;
    justify-content: center;
}

.bundle-switch-preview-button > button {
min-width: 100px;
}

.bundle-snippet-code {
    padding: 15px 0px 5px 0;
    font-size: 14px;
    font-family: monospace;
}

.bundle-snippet-code code {
    width: 100%;
}

.remove-item {
    margin-right: 7px;
}

.view-bundle {
    margin-right: 15px;
}

.duplicate-item {
    cursor: pointer;
    margin-right: 7px;
}

.delete-dup-buttons {
    display: flex;
    align-self: center;
    position: relative;
}

.remove-bulk-warranty {
    cursor: pointer;
}

.create-bundle-buttons {
    margin-bottom: 10px;
    display: flex;
        align-items: center;
}

.create-auto-bundle-button {
    margin-left: 5px;
        display: flex;
    align-items: center;
}

.bundle-name {
    font-weight: 600;
    display: flex;
    width: 15%;
}

.bundle-discount {
    display: flex;
    width: 10%;
    margin-left: 10px;

}

.bundle-product-titles {
    display: flex;
    width: 25%;
}


@media only screen and (max-width : 900px) {
    .bundle-product-titles {
        display: none;
    }

    .bundle-product-image {
        width: 25px;
        height: 25px;
    }

    .bundle-name {
        font-size: 10px;
    }

    .bundle-discount {
        font-size: 10px;

    }

}

.bundle-product-images {
    display: flex;
    width: 15%;
}

.bundle-list-buttons {
    margin-left: auto;
    display: flex;
}

.add-product-button-wrapper {
    margin-right: 10px;
}

.product-to-show-on-title {
    margin-right: 10px;
}

.bundle-variant-item {
    display: flex;
    align-items: center;
    padding: 5px 0px;
}

.bundle-variant-item-img{
    width: 22px;
    height: 22px;
    border: solid 1px #cfcfcf;
}

.bundle-variant-item-title {
    margin-left: 7px;
}

.bundle-variant-button {
    margin-right: 5px;
}

.bundle-disable-button {
    min-width: 100px;
    display: flex;
    justify-content: center;
}

@media (max-width: 720px) {

    .bundle-show-short-code-button {
        display: none;
    }

}

.field-tip.auto-bundle {margin-top: 40px;}

.auto-bundle-titles, .imported-bundle-titles {
    margin-top: 15px;
    margin-bottom: 5px;
    font-weight: 500;
}

[data-tooltip]:before {
    position : absolute;
    content : attr(data-tooltip);
    top: -22px;
    font-size: 8px;
    border: 1px solid lightgrey;
    padding: 0  3px 0 3px;
    color: #434343;
    border-radius: 5px;
    background-color: #f9f9f9;
    font-weight: bold;
    visibility: hidden;
}

[data-tooltip]:hover:before {
    visibility: visible;
}

.dup-spinner {
    display: flex;
    place-self: center;
    min-width: 25px;
}

.display-options-buttons {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.bundle-display-group {
    display: flex;
    align-items: center;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #cdcdcd;
}


.bundle-display-group-text {
    margin-left: 5px;
}

.bundle-save-button {
    margin-top: 30px;
}

.create-group-bundle-button {
    margin-left: 5px;
        display: flex;
    align-items: center;
}

.managne-bundle-order-button {
    margin-left: 5px;
}

.bundle-titles-locales {
    display: flex;
    flex-direction: column;
    border: 1px solid lightgray;
    padding: 0px 10px 15px 15px;
    margin-top: 10px;
}

.bundle-locale  {
    width: 100%;
}

.bundle-titles-wrapper {
    width: 100%;

}

.navigation.bundle-edit-nav { padding-bottom: 0 }

.bundle-pagination {
    text-align: center;
    padding-top: 20px;
    border-top: 1px solid rgb(218, 218, 218);
}

.show-bundle-by > div {
 display: inline-flex;
}

.show-bundle-by {
    margin-left: auto;

}

.show-bundle-by .Polaris-Select { margin-left: 5px;}

.bundle-name-label {
    margin-bottom: 5px;
    margin-right: 2px;

}

.bundle-name-text-field {
    margin-top:4px;
}

.bundle-edit-form-layout .Polaris-FormLayout__Item:nth-child(3) { margin-top: 2px; }

.bundle-variant-selection {
    max-height: 200px;
}

.qty-bundle-discount-wrapper {
    display: flex;
}

.qty-bundle-discount-wrapper > div:first-child { width: 100%; margin-right: 10px; }
.qty-bundle-discount-wrapper > div:nth-child(2) { width: 100px;}

.import-bundle-csv {
    margin-left: 5px;
    display: flex;
    align-items: center;
}

.bundle-cart-options-wrapper {
    padding: 5px 10px;
    border: 1px solid #dadada;
}

.bundle-cart-options-title { margin-bottom: 5px;}

.video-tuturial-tigger-icon {
    padding-left: 4px;
    padding-right: 2px;
    position: relative;
    white-space: nowrap;
}

.create-bundle-wrapper {
    padding: 10px;
    border: 1px solid #c7c7c7;
    border-radius: 10px;
    align-items: center;
    display: flex;
}

.bundle-items-selection-wrapper {
    padding: 10px 20px 20px 20px;
    background-color: #f7f7f7;
    border-radius: 10px;
    border: 1px solid #dfdfdf;
}

.bundle-item-selection-wrapper:not(:nth-child(2)) {
    margin-top: 10px;
}

.bundle-items-selection-title {
    font-size: 17px;
    font-weight: 500;
    margin-bottom: 5px;;
}

.grouped-bundle-type .duplicate-item { display: none;}

.grouped-bundle-type .delete-dup-buttons { margin-left: 26px; }

.bundle-order-container .bundle-order-item-wrapper {
        display: flex;
    border: 1px solid #f1f1f1;
    margin-bottom: -1px;
    padding: 7px;
}

.bundle-order-container .bundle-order-item-wrapper .bundle-order-arrow-wrapper {
    width: 48px;
    text-align: center;
    display: inline-block;
}

.bundle-order-item-wrapper .bundle-order-spinner-wrapper {
    width: 50px;
    display: flex;
    justify-content: center;
}

.bundle-ordered-list-wrapper {
    padding: 15px;
    border: 1px solid #ebebeb;
    margin-top: 10px;
    border-radius: 10px;
}